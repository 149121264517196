.cpcs-esis .cta{  
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  -webkit-transition-property: color, background-color, border-color, border-bottom-color;
  transition-property: color, background-color, border-color, border-bottom-color;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-timing-function: cubic-bezier(0.33, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.33, 0, 0.3, 1);
  font-family: 'SamsungOne', arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 700;
}
[dir = "rtl"] .cpcs-esis .cta { font-family: 'SamsungOneArabic', arial, sans-serif; }
/* Contained */
.cpcs-esis .cta--contained { padding: 10px 24px 11px 24px; border-radius: 20px; border: none;}
.cpcs-esis .cta--contained.cta--black { color: #fff !important; background-color: #000}
.cpcs-esis .cta--contained.cta--black:hover,
.cpcs-esis .cta--contained.cta--black:focus { color: #eee !important; background-color: #555 !important; }
.cpcs-esis .cta--contained.cta--black.cta--disabled { color: #aaa !important; background-color: #ddd !important; cursor: default !important }
.cpcs-esis .cta--contained.cta--black.cta--disabled:hover,
.cpcs-esis .cta--contained.cta--black.cta--disabled:focus { color: #aaa !important; background-color: #ddd !important }
.cpcs-esis .cta--contained.cta--black[target="_blank"]:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-white.svg') !important }
.cpcs-esis .cta--contained.cta--black[target="_blank"]:hover:before,
.cpcs-esis .cta--contained.cta--black[target="_blank"]:focus:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-white-dim.svg') !important }
.cpcs-esis .cta--contained.cta--black[target="_blank"].cta--disabled:before  { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-white-dim.svg') !important }
.cpcs-esis .cta--contained.cta--white { color: #000 !important; background-color: #fff !important }
.cpcs-esis .cta--contained.cta--white:hover,
.cpcs-esis .cta--contained.cta--white:focus {color: #313131 !important;  background-color: #ddd !important;}
.cpcs-esis .cta--contained.cta--white[target="_blank"]:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-black.svg') !important }
.cpcs-esis .cta--contained.cta--white[target="_blank"]:hover:before,
.cpcs-esis .cta--contained.cta--white[target="_blank"]:focus:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-black-dim.svg') !important }
.cpcs-esis .cta--contained.cta--white[target="_blank"].cta--disabled:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-black-dim.svg') !important }

/* Outlined */
.cpcs-esis .cta--outlined { padding: 9px 23px 10px 23px;
  border-radius: 20px; border-width: 1px; border-style: solid }
.cpcs-esis .cta--outlined.cta--black:focus { color: #555 !important; background-color: rgba(0, 0, 0, 0.05) !important; border-color: #555 !important }
.cpcs-esis .cta--outlined.cta--black.cta--disabled { color: #ddd !important; border-color: #ddd !important; cursor: default !important }
.cpcs-esis .cta--outlined.cta--black.cta--disabled:hover,
.cpcs-esis .cta--outlined.cta--black.cta--disabled:focus { color: #555 !important; background-color:  rgba(0,0,0,0.05) !important;  border-color: #555 !important}
.cpcs-esis .cta--outlined.cta--black[target="_blank"]:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-black.svg') !important }
.cpcs-esis .cta--outlined.cta--black[target="_blank"]:hover:before,
.cpcs-esis .cta--outlined.cta--black[target="_blank"]:focus:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-gray.svg') !important }
.cpcs-esis .cta--outlined.cta--black[target="_blank"].cta--disabled:before,
.cpcs-esis .cta--outlined.cta--black[target="_blank"].cta--disabled:hover:before,
.cpcs-esis .cta--outlined.cta--black[target="_blank"].cta--disabled:focus:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-gray-dim.svg') !important }
.cpcs-esis .cta--outlined.cta--white { color: #fff !important; background-color: transparent !important; border-color: #fff !important }
.cpcs-esis .cta--outlined.cta--white:hover,
.cpcs-esis .cta--outlined.cta--white:focus { color: #ddd !important; background-color: rgba(255, 255, 255, 0.15) !important; border-color: #ddd !important }
.cpcs-esis .cta--outlined.cta--white.cta--disabled { color: #555 !important; border-color: #555 !important; cursor: default !important }
.cpcs-esis .cta--outlined.cta--white.cta--disabled:hover,
.cpcs-esis .cta--outlined.cta--white.cta--disabled:focus { color: #555 !important; background-color: transparent !important; border-color: #555 !important }
.cpcs-esis .cta--outlined.cta--white[target="_blank"]:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-white.svg') !important }
.cpcs-esis .cta--outlined.cta--white[target="_blank"]:hover:before,
.cpcs-esis .cta--outlined.cta--white[target="_blank"]:focus:before { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-gray-dim.svg') !important }
.cpcs-esis .cta--outlined.cta--white[target="_blank"].cta--disabled:before,
.cpcs-esis .cta--outlined.cta--white[target="_blank"].cta--disabled:hover:before,
.cpcs-esis .cta--outlined.cta--white[target="_blank"].cta--disabled:focus:before  { background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-new-window-gray.svg') !important }

/* underlined */
.cpcs-esis .cta--underlined {  padding: 10px 0 3px 0; font-weight: 700;}
.cpcs-esis .cta--underlined:after { content: ' '; position: absolute; left: 0; right: 0;  bottom: 0px; height: 2px !important; background-color: #000; }
.cpcs-esis .cta--underlined:hover:after,
.cpcs-esis .cta--underlined:focus:after{ -webkit-animation: cta-underlined-animation .2s both; animation: cta-underlined-animation .2s both; -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); -webkit-animation-delay: .1s; animation-delay: .1s }
.cpcs-esis .cta--underlined.cta--arrow{ font-weight: 700; color: #000; }
.cpcs-esis .cta--underlined.cta--arrow :after { content: ">"; padding: 0 0 0 5px; font-weight: 700; color: #000; }
html[dir="rtl"] .cpcs-esis .cta--underlined.cta--arrow :after {content: ">";  font-weight: 700; color: #000;  padding: 0 5px 0 0;font-family: 'SamsungOne';
  font-weight: 400; }
/*  regularText */
.cpcs-esis .cta.cta--regularText.cta--white{ font-weight: 700; color: #ffffff; }
.cpcs-esis .cta.cta--regularText.cta--black{ font-weight: 700; color: #000000; }
.cpcs-esis .cta--regularText.cta--white:after { content: ">"; padding: 0 0 0 5px; font-weight: 700; color: #ffffff; }
.cpcs-esis .cta--regularText.cta--black:after { content: ">"; padding: 0 0 0 5px; font-weight: 700; color: #000; }
html[dir="rtl"] .cpcs-esis .cta--regularText.cta--white:after {content: ">";  font-weight: 700;color: #ffffff;   padding: 0 5px 0 0; }
html[dir="rtl"] .cpcs-esis .cta--regularText.cta--black:after {content: ">";  font-weight: 700; color: #000;  padding: 0 5px 0 0; }

@media only screen and (min-width:1025px) and (max-width:1440px) {
  .cpcs-esis .cta {  line-height: 1.31944vw ;  font-size:0.97222vw; }
  /* Contained */
  .cpcs-esis .cta--contained { padding: .69444444vw 1.66666667vw .76388889vw 1.66666667vw; border-radius: 1.38888889vw }
  /* Outlined */
  .cpcs-esis .cta--outlined { padding: .625vw 1.59722222vw .69444444vw 1.59722222vw; border-radius: 1.38888889vw }
  /* underlined */
  .cpcs-esis .cta--underlined { padding: .69444444vw 0vw  0.20833vw 0vw }

  .cpcs-esis .cta--underlined.cta--arrow :after {  padding: 0 0 0 0.34722vw; }
  html[dir="rtl"] .cpcs-esis .cta--underlined.cta--arrow :after { padding: 0 0.34722vw 0 0; }
  .cpcs-esis .cta--regularText :after { padding: 0 0 0 0.34722vw;  }
  html[dir="rtl"] .cpcs-esis .cta--regularText:after { padding: 0 0.34722vw 0 0; }

}
@media only screen and (min-width:768px) and (max-width:1024px) {
  .cpcs-esis .cta { line-height: 1.31944vw ;  font-size: 0.97222vw; }
  /* Contained */
  .cpcs-esis .cta--contained { padding: .69444444vw 1.66666667vw .76388889vw 1.66666667vw; border-radius: 1.38888889vw }
  /* Outlined */
  .cpcs-esis .cta--outlined { padding: .625vw 1.59722222vw .69444444vw 1.59722222vw; border-radius: 1.38888889vw }
  /* underlined */
  .cpcs-esis .cta--underlined { padding: .69444444vw 0vw  0.20833vw 0vw }

  .cpcs-esis .cta--underlined.cta--arrow :after {  padding: 0 0 0 0.34722vw; }
  html[dir="rtl"] .cpcs-esis .cta--underlined.cta--arrow :after { padding: 0 0.34722vw 0 0; }
  .cpcs-esis .cta--regularText :after { padding: 0 0 0 0.34722vw;  }
  html[dir="rtl"] .cpcs-esis .cta--regularText:after { padding: 0 0.34722vw 0 0; }

}

@media only screen and (max-width:767px) {
  .cpcs-esis .cta {  font-size: 3.666667vw; line-height: 5.27778vw; }
  /* Contained */
  .cpcs-esis .cta--contained {    padding: 2.77777778vw 6.66666667vw 3.05555556vw 6.66666667vw;
    border-radius: 5.55555556vw }
  /* Outlined */
  .cpcs-esis .cta--outlined {   padding: 2.5vw 6.38888889vw 2.77777778vw 6.38888889vw;
    border-radius: 5.55555556vw;}
  /* underlined */
  .cpcs-esis .cta--underlined { padding: 2.77778vw 0 0.83333vw 0 }
  .cpcs-esis .cta--underlined:after { height: .55555556vw !important;  }
  .cpcs-esis .cta--underlined.cta--arrow :after { padding: 0 0 0 1.38889vw; }
  html[dir="rtl"] .cpcs-esis .cta--underlined.cta--arrow :after { padding: 0 1.38889vw 0 0; }
  .cpcs-esis .cta--regularText:after { padding: 0 0 0 1.38889vw; }
  html[dir="rtl"] .cpcs-esis .cta--regularText:after { padding: 0 1.38889vw 0 0; }

}

 
@-webkit-keyframes cta-underlined-animation {
  from { visibility: hidden; -webkit-transform: scaleX(0); transform: scaleX(0) }
  to { visibility: visible; -webkit-transform: scaleX(1); transform: scaleX(1) }}

@keyframes cta-underlined-animation {
  from { visibility: hidden; -webkit-transform: scaleX(0); transform: scaleX(0) }
  to { visibility: visible; -webkit-transform: scaleX(1); transform: scaleX(1) }}