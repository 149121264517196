.cpcs-esis-story-square-wrapper { width: 100%; margin: 0 auto; padding: 60px;  background-color: #f7f7f7;}
.cpcs-esis-story-square-inner { width: 100%; margin: 0 auto;  padding: 0;  display: flex; flex-wrap: wrap;}

/* item */
.cpcs-esis-story-square-item { width: 100%; margin: 0; padding: 0;}
.cpcs-esis-story-square-item-inner { width: 570px; margin: 0 auto; padding: 10px;  text-align: center; overflow: hidden; flex-basis: content; height: 620px;  }
.cpcs-esis-story-square-item-img { width: 550px;  height: 340px; margin: 0 auto; padding: 0; }
.cpcs-esis-story-square-item-content { width: 100%; margin: 0 auto; padding: 10px 30px;}
.cpcs-esis-story-square-item-eyebrow-text{ width: 100%; max-width: 530px; height:60px;  padding: 20px 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.cpcs-esis-story-square-item-eyebrow-text p { font-size: 14px;}
.cpcs-esis-story-square-item-title{ width: 100%; max-width: 530px; height:70px;  display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.cpcs-esis-story-square-item-description{ width: 100%;  max-width: 530px; height:90px;  display: -webkit-box; -webkit-line-clamp: 3;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; padding: 20px 0;}

@media (min-width: 1025px) and (max-width: 1440px) {
.cpcs-esis-story-square-wrapper { padding: 4.16667vw;}
.cpcs-esis-story-square-item-inner { width: 39.58333vw; padding: 0.69444vw; height: 43.05556vw;  }
.cpcs-esis-story-square-item-content { padding: 0.69444vw  2.08333vw; }
.cpcs-esis-story-square-item-img { width: 38.19444vw; height: 23.61111vw; }
.cpcs-esis-story-square-item-eyebrow-text{ max-width: 36.80556vw; height:4.16667vw;  padding:1.38889vw 0; }
.cpcs-esis-story-square-item-eyebrow-text p { font-size: 0.97222vw; }
.cpcs-esis-story-square-item-title{ max-width: 36.80556vw; height:5.86111vw; }
.cpcs-esis-story-square-item-description{  max-width: 36.80556vw; height:6.25000vw; padding:1.38889vw 0;}

}

@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis-story-square-wrapper { padding: 4.16667vw;}
  .cpcs-esis-story-square-item-inner { width: 39.58333vw; padding: 0.69444vw; height: 43.05556vw;  }
  .cpcs-esis-story-square-item-img { width: 38.19444vw; height: 23.61111vw; }
  .cpcs-esis-story-square-item-content { padding: 0.69444vw  2.08333vw; }
  .cpcs-esis-story-square-item-eyebrow-text{ max-width: 36.80556vw; height:4.16667vw;  padding:1.38889vw 0; }
  .cpcs-esis-story-square-item-eyebrow-text p { font-size: 0.97222vw; }
  .cpcs-esis-story-square-item-title{ max-width: 36.80556vw; height:5.86111vw; }
  .cpcs-esis-story-square-item-description{  max-width: 36.80556vw; height:6.25000vw; padding:1.38889vw 0;}
}

@media (max-width: 767px) {
  .cpcs-esis-story-square-wrapper { padding: 13.33333vw 0 8.33333vw;}
  .cpcs-esis-story-square-item-inner { width: 100%; padding: 2.7778vw; height:130.9444vw;  }
  .cpcs-esis-story-square-item-img { width: 100%; height: 57.88888889vw; padding: 0; }
  .cpcs-esis-story-square-item-content { padding: 0.97222vw; }
  .cpcs-esis-story-square-item-eyebrow-text{ max-width: 98vw; height:16.66667vw;  padding: 5.27778vw 0; }
  .cpcs-esis-story-square-item-eyebrow-text p { font-size: 3.88889vw; }
  .cpcs-esis-story-square-item-title{ max-width: 98vw; height:16.66667vw; }
  .cpcs-esis-story-square-item-description{ max-width: 98vw; height:22.00000vw; padding: 5.27778vw 0;}
}