
.cpcs-esis-section-title-wrapper { width: 100%; margin: 0 auto;  text-align: center;}
.cpcs-esis-section-title-bg-darkgray { background-color: #313131;}
.cpcs-esis-section-title-bg-lightgray { background-color: #f7f7f7;}
.cpcs-esis-section-title-bg-black { background-color: #000000;}
.cpcs-esis-section-title-bg-white { background-color: #ffffff;}
.cpcs-esis-section-title-bg-transparent { background-color: transparent;}
.cpcs-esis-section-title-pt-large { padding-top: 60px;}
.cpcs-esis-section-title-pb-large { padding-bottom: 60px;}
.cpcs-esis-section-title-pt-medium { padding-top: 40px;}
.cpcs-esis-section-title-pb-medium { padding-bottom: 40px;}
.cpcs-esis-section-title-pt-small { padding-top: 20px;}
.cpcs-esis-section-title-pb-small { padding-bottom: 20px;}
.cpcs-esis-section-title-subtitle { padding-top: 20px;}

@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis-section-title-pt-large { padding-top: 4.16667vw;}
  .cpcs-esis-section-title-pb-large { padding-bottom: 4.16667vw;}
  .cpcs-esis-section-title-pt-medium { padding-top: 2.77778vw;}
  .cpcs-esis-section-title-pb-medium { padding-bottom: 2.77778vw;}
  .cpcs-esis-section-title-pt-small { padding-top: 1.38889vw;}
  .cpcs-esis-section-title-pb-small { padding-bottom: 1.38889vw;}
  .cpcs-esis-section-title-subtitle { padding-top: 1.38889vw;}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis-section-title-pt-large { padding-top: 4.16667vw;}
  .cpcs-esis-section-title-pb-large { padding-bottom: 4.16667vw;}
  .cpcs-esis-section-title-pt-medium { padding-top: 2.77778vw;}
  .cpcs-esis-section-title-pb-medium { padding-bottom: 2.77778vw;}
  .cpcs-esis-section-title-pt-small { padding-top: 1.38889vw;}
  .cpcs-esis-section-title-pb-small { padding-bottom: 1.38889vw;}
  .cpcs-esis-section-title-subtitle { padding-top: 1.38889vw;}
}

@media (max-width: 767px) {
  .cpcs-esis-section-title-wrapper { padding-left: 2.77778vw; padding-right: 2.777778vw;}
  .cpcs-esis-section-title-pt-large { padding-top:7.22222vw;}
  .cpcs-esis-section-title-pb-large { padding-bottom:7.22222vw;}
  .cpcs-esis-section-title-pt-medium { padding-top:5.27778vw;}
  .cpcs-esis-section-title-pb-medium { padding-bottom:5.27778vw;}
  .cpcs-esis-section-title-pt-small { padding-top: 2.77778vw;}
  .cpcs-esis-section-title-pb-small { padding-bottom: 2.77778vw;}
  .cpcs-esis-section-title-subtitle { padding-top: 2.77778vw;}
}