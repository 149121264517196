/* bootstrap css */
.cpcs-esis .cpcs-esis-dropdown-menu { position: absolute; top: 97%; left: 0; z-index: 1000; display: none; float: left;  padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: #212529; text-align: left; list-style: none; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 0.25rem; }
[dir="rtl"] .cpcs-esis .cpcs-esis-dropdown-menu { position: absolute; top: 97%; right: 0; left:auto; z-index: 1000; display: none; float: right;  padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: #212529; text-align: right; list-style: none; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 0.25rem;  }
.cpcs-esis .dropUp .cpcs-esis-dropdown-menu { margin-top: 0; margin-bottom: 0.125rem; }
.cpcs-esis .dropUp .cpcs-esis-dropdown-toggle::after { display: inline-block; width: 0; height: 0; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0; border-right: 0.3em solid transparent; border-bottom: 0.3em solid; border-left: 0.3em solid transparent; }
.cpcs-esis .dropUp .cpcs-esis-dropdown-toggle:empty::after { margin-left: 0; }
.cpcs-esis .cpcs-esis-dropdown-item { display: block; width: 100%; padding: 0.25rem 1.5rem; clear: both; font-weight: 400; color: #212529; text-align: inherit; white-space: nowrap; background-color: transparent; border: 0; }
.cpcs-esis .cpcs-esis-dropdown-menu.show { display: block; }
.cpcs-esis .nav { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; padding-left: 0; margin-bottom: 0; list-style: none; }
.cpcs-esis .cpcs-esis-nav-link { display: block; padding: 0.5rem 1rem; }
.cpcs-esis .cpcs-esis-nav-link:hover, .cpcs-esis.cpcs-esis-nav-link:focus { text-decoration: none; }
.cpcs-esis .cpcs-esis-nav-link.disabled { color: #6c757d; }
.cpcs-esis .cpcs-esis-navbar { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; width: 100%; }
.cpcs-esis .cpcs-esis-navbar-brand { display: inline-block; white-space: nowrap; }
.cpcs-esis .cpcs-esis-navbar-brand:hover, .cpcs-esis .cpcs-esis-navbar-brand:focus { text-decoration: none; }
.cpcs-esis .cpcs-esis-navbar-nav { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; padding-left: 0; margin-bottom: 0; list-style: none; }
.cpcs-esis .cpcs-esis-navbar-nav .cpcs-esis-nav-link { padding-right: 0; padding-left: 0; }
.cpcs-esis .cpcs-esis-navbar-nav .cpcs-esis-dropdown-menu { position: static; float: none; }
.cpcs-esis .cpcs-esis-navbar-collapse { -ms-flex-preferred-size: 100%; flex-basis: 100%; -webkit-box-flex: 1; -ms-flex-positive: 1; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.cpcs-esis .cpcs-esis-navbar-toggler:hover, .cpcs-esis .cpcs-esis-navbar-toggler:focus { text-decoration: none; }
.cpcs-esis .cpcs-esis-navbar-toggler:not(:disabled):not(.disabled) { cursor: pointer; }


@media (min-width:  767px) { 
  .cpcs-esis .cpcs-esis-navbar-expand-lg { -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav { -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-dropdown-menu { position: absolute;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link { padding-right: 0.5rem; padding-left: 0.5rem;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-collapse { display: -webkit-box !important; display: -ms-flexbox !important; display: flex !important; -ms-flex-preferred-size: auto; flex-basis: auto;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-toggler { display: none;  } 
  .cpcs-esis .cpcs-esis-navbar-expand-lg .dropUp .cpcs-esis-dropdown-menu { top: auto; bottom: 100%;  } 
}

/* Custom css */
.cpcs-esis .cpcs-esis-navbar { padding: 0 24px; background-color: #ffffff; font-family: 'SamsungSharpSans'; display: flex; align-items: center; color: #000000;font-family: 700; }
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar {  font-family: 'SamsungSharpSans','SamsungOneArabic'; font-family: 700;}
.cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-brand { padding: 25px 0; cursor: pointer;}  
/* .cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-collapse.collapse { margin-left: auto;} */

.cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-collapse.collapse ul { margin:0; }
.cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item { margin: 0; padding: 0; padding-right: 25px; }
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item {  padding-left: 25px; }
.cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:last-child { padding-right: 0; }
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:last-child { padding-left: 0; }
.cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link { text-decoration: none; color: #000000; border-bottom: 2px solid transparent; font-family: 'SamsungSharpSans'; font-size: 14px; line-height: 1.2; font-weight: 700; padding: 10px 0 10px 0; letter-spacing: 0.5;}
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link {  font-family: 'SamsungSharpSans','SamsungOneArabic'; font-family: 700;}
.cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:hover .cpcs-esis-nav-link, .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:active .cpcs-esis-nav-link, .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:focus .cpcs-esis-nav-link { border-bottom: 2px solid #000000; }
.cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-menu { border: none; box-shadow: 2px 2px 10px rgb(25 25 25 / 10%);}
.cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item { text-decoration: none; font-family: 'SamsungOne'; font-size: 14px; line-height: 1.2; padding: 10px 20px;  }
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item { font-family: 'SamsungOneArabic'; }
.cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:hover span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:focus span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item span:active { border-bottom: 1px solid #000000; }
.cpcs-esis .cpcs-esis-dropdown { min-width: 0;}
.cpcs-esis .cpcs-esis-nav-link.cpcs-esis-dropdown-toggle .cpcs-esis-dropdown-btn-icon {display: none;}
.cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link.active { border-bottom: 2px solid #000000 !important;}

/* customNewCss */
.cpcs-esis .cpcs-esis-navbar ul{  list-style: none; margin: 0; padding: 0;}
.cpcs-esis .cpcs-esis-navbar-nav.ml-auto { margin-left: auto;}
.cpcs-esis .nav-arrows {display:  flex; justify-content: space-between; align-items: center;}
 .cpcs-esis-navbar-close-btn{ display: none;}

@media (min-width:1025px) and (max-width: 1440px) {
  .cpcs-esis-dropdown:hover>.cpcs-esis-dropdown-menu { display: block; }
  .cpcs-esis-dropdown>.cpcs-esis-dropdown-toggle:active { pointer-events: none; }
  .cpcs-esis .cpcs-esis-navbar { padding: 0 1.666667vw; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-brand { padding: 1.73611vw 0;}  
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item {  padding-right: 1.73611vw; }
  [dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item {  padding-left: 1.73611vw; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link { font-size: 0.97222vw; padding: 0.69444vw 0 0.69444vw 0; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item {font-size: 0.97222vw;  padding: 0.69444vw 1.38889vw;  }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:hover span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:focus span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item span:active { border-bottom: 1px solid #000000; }
}

@media (min-width:768px) and (max-width: 1024px) {
  .cpcs-esis-dropdown:hover>.cpcs-esis-dropdown-menu { display: block; }
  .cpcs-esis-dropdown>.cpcs-esis-dropdown-toggle:active { pointer-events: none; }
  .cpcs-esis .cpcs-esis-navbar { padding: 0 1.666667vw; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-brand { padding: 1.73611vw 0;}  
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item {  padding-right: 1.73611vw; }
  [dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item {  padding-left: 1.73611vw; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link { font-size: 1.11111vw; padding: 0.69444vw 0 0.69444vw 0; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item {font-size:1.11111vw;  padding: 0.69444vw 1.38889vw;  }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:hover span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item:focus span, .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item span:active { border-bottom: 1px solid #000000; }
}
@media (min-width: 1441px) {
  .cpcs-esis-dropdown:hover>.cpcs-esis-dropdown-menu { display: block; }
  .cpcs-esis-dropdown>.cpcs-esis-dropdown-toggle:active { pointer-events: none; }
}

@media (max-width: 767px) { 
  .cpcs-esis .cpcs-esis-navbar { padding: 0 5.55556vw; box-shadow: 2px 2px 10px rgb(25 25 25 / 10%); justify-content: space-between;}
  .cpcs-esis .cpcs-esis-navbar-toggler { font-family: 'SamsungSharpSans'; font-size: 12px; line-height: 1; font-weight: 700; padding: 0; display: flex;align-items: center; justify-content: space-between;  background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem; color: #000!important;}
  [dir="rtl"] .cpcs-esis .cpcs-esis-navbar-toggler { font-family: 'SamsungSharpSans','SamsungOneArabic'; font-size: 12px; line-height: 1; font-weight: 700; padding: 0; display: flex;align-items: center; justify-content: space-between;  background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem; color: #000!important;}
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item { padding-right: 0; position: relative; }
  [dir="rtl"] .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item { padding-left: 0; position: relative; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item { padding: 0 0 5.55556vw; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item .cpcs-esis-nav-link { padding: 0 0 1.38889vw; display: inline-block;}
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:hover .cpcs-esis-nav-link, .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:active .cpcs-esis-nav-link, .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item:focus .cpcs-esis-nav-link { border-bottom: 2px solid transparent }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item.cpcs-esis-dropdown.show .cpcs-esis-nav-link { padding: 0 0 10px; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown { min-width: 100% }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-menu{ box-shadow: none; }
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-item { padding: 0 0 5.55556vw; }
  .cpcs-esis .cpcs-esis-nav-link.cpcs-esis-dropdown-toggle .cpcs-esis-dropdown-btn-icon {display: block;}
  .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-toggle:empty::after { margin-left: 0; }
  [dir="rtl"] .cpcs-esis .cpcs-esis-navbar .cpcs-esis-dropdown-toggle:empty::after { margin-right: 0; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-item.cpcs-esis-dropdown:active .cpcs-esis-nav-link.cpcs-esis-dropdown-toggle { border-bottom: 2px solid transparent !important }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-dropdown-toggle .cpcs-esis-dropdown-label{padding: 0 0 1.38889vw;}
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link.active { border-bottom: 2px solid #000000;}
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link.active.cpcs-esis-dropdown-toggle .cpcs-esis-dropdown-label{border-bottom: 2px solid #000000 !important; }
  .cpcs-esis .cpcs-esis-navbar-expand-lg .cpcs-esis-navbar-nav .cpcs-esis-nav-link.active.cpcs-esis-dropdown-toggle{ border-bottom: 2px solid transparent !important; }
  .cpcs-esis .cpcs-esis-dropdown-toggle {display: flex !important; align-items: center; justify-content: space-between; width: 100%; flex-grow: 1;} 
  .cpcs-esis .cpcs-esis-navbar-collapse.collapse{ width: 0; position: fixed; top:0; right:0px; bottom: 0px; background: #ffffff; z-index: 2147483647;   overflow-x: hidden; transition: width 0.3s linear;  overflow-y:scroll ; } 
  .cpcs-esis .cpcs-esis-navbar-collapse.collapse.hide{ width:0; } 
  .cpcs-esis .cpcs-esis-navbar-collapse.collapse.show { width:100%;   overflow-y:scroll ;} 
  .cpcs-esis .cpcs-esis-navbar-collapse.collapse ul{ padding: 5.55556vw 5.55556vw 15.55556vw; } 
  .cpcs-esis .cpcs-esis-navbar-btn-container { display: flex; flex-grow: 1; justify-content: flex-end; align-items: center; }
  .cpcs-esis .cpcs-esis-navbar-close-btn { display: block; background-color: transparent; border: none; padding: none; cursor: pointer;  padding: 29px 5.55556vw 0;  user-select: none; }
}


.cpcs-esis-dropdown-menu li {
  position: relative;
  }
  .cpcs-esis-dropdown-menu .cpcs-esis-dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .cpcs-esis-dropdown-menu .cpcs-esis-dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .cpcs-esis-dropdown-menu > li:hover > .cpcs-esis-dropdown-submenu {
  display: block;
  }


/* dir-rtl */
[dir="ltr"] .cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-collapse.collapse { margin-left: auto;}
[dir="rtl"] .cpcs-esis .cpcs-esis-navbar .cpcs-esis-navbar-collapse.collapse { margin-right: auto;}