.cpcs-esis_custom-banner { width: 100%; height: 100%; position: relative; display: inline-block;}
.cpcs-esis_custom-banner-bg { width: 100%; height: 100%; position: relative; }
.cpcs-esis_custom-banner-bg .img-wrapper { display: inline-block; width: 100%; height: 100%; }
.cpcs-esis_custom-banner-content-area { margin: 0; position: absolute; z-index: 1; text-align: left; }
[dir="rtl"] .cpcs-esis_custom-banner-content-area { margin: 0; position: absolute; z-index: 1; text-align: right; }
.cpcs-esis_custom-banner-content-desc { padding: 20px 0 0; }

@media (min-width:768px) and (max-width: 1440px) {
  .cpcs-esis_custom-banner-bg .img-wrapper img { width: 100%; }
  .cpcs-esis_custom-banner-content-desc { padding: 1.333333vw 0 0; }   
}

@media (max-width: 767px) {
  .cpcs-esis_custom-banner-bg .img-wrapper img { width: 100%; }
  .cpcs-esis_custom-banner-content-area { width: 100%; text-align: center; }
  [dir="rtl"] .cpcs-esis_custom-banner-content-area { width: 100%; text-align: center;  }
  .cpcs-esis_custom-banner-content-desc { padding: 5.55556vw 0 0; }
}


