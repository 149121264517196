.cpcs-esis_banner-product_container { width: 100%; margin: 0 auto; padding: 0; display: grid;  grid-auto-flow: dense;  gap: 10px; overflow: hidden; height: 380px;}
.cpcs-esis_banner-product_big_tile { width: 100%; height: 380px;}
.cpcs-esis_banner-product_small_tile {width: 100%; height: 185px;}

@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis_banner-product_container { gap:0.69444vw; height: 26.38889vw; }
  .cpcs-esis_banner-product_big_tile { height: 26.38889vw;}
  .cpcs-esis_banner-product_small_tile { height: 12.847vw;}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis_banner-product_container { gap:0.69444vw; height: 26.38889vw; padding: 0 0.13889vw;}
  .cpcs-esis_banner-product_big_tile { height: 26.38889vw;}
  .cpcs-esis_banner-product_small_tile { height: 12.847vw;}
}

@media (max-width: 767px) {
  .cpcs-esis_banner-product_container {  display: flex;  flex-direction: column; gap: 2.77778vw; height: 257.5551vw; }
  .cpcs-esis_banner-product_big_tile { height: 148.444vw;}
  .cpcs-esis_banner-product_small_tile { height: 51.77777778vw; padding: 0 2.77778vw;}
}
