.cpcs-esis_story-telling-listing { width: 100%; margin: 0; }
/* .cpcs-esis_story-telling:last-child .cpcs-esis_story-telling-listing {padding:0 0 70px ;} */
/* .cpcs-esis_story-telling .cpcs-esis_story-telling-listing.cpcs-esis_story-telling-first-item  {  padding: 70px 0 0;} */
.cpcs-esis_story-telling-row { width: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; font-size: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
.cpcs-esis_story-telling-col { width: 50%; display: inline-block; -webkit-box-sizing: border-box; box-sizing: border-box; margin: 0; font-size: 0; }
.cpcs-esis_story-telling:nth-child(odd) .cpcs-esis_story-telling-row{-ms-flex-direction: row-reverse; flex-direction: row-reverse;}
.cpcs-esis-section-wrapper.cpcs-esis_story-telling  .cpcs-esis-section-inner { padding: 0 !important;}
.cpcs-esis_story-telling-content-area-inner { padding: 60px 20px 20px 60px; text-align: left; }
[dir="rtl"] .cpcs-esis_story-telling-content-area-inner { padding: 60px 60px 20px 20px; text-align: right; }
.cpcs-esis_story-telling-content-title { padding: 10px 0; }
.cpcs-esis_story-telling-content-subtitle { padding: 10px 0 0; }
.cpcs-esis_story-telling-content-description { padding: 10px 0; }
.cpcs-esis_story-telling-content-disclaimer { padding: 20px 0 10px; }
.cpcs-esis_story-telling-content-cta { padding: 15px 0; }

@media (min-width: 1025px) and (max-width: 1440px) {
    .cpcs-esis-section-wrapper.cpcs-esis_story-telling  .cpcs-esis-section-inner { padding: 0 !important;}
    /* .cpcs-esis-section-wrapper.cpcs-esis_story-telling:last-child .cpcs-esis_story-telling-listing  { padding:0 0 4.86111vw 0; }
    .cpcs-esis_story-telling .cpcs-esis_story-telling-listing.cpcs-esis_story-telling-first-item  { padding: 4.86111vw 0 0; } */
    .cpcs-esis_story-telling-content-area-inner { padding: 4.16667vw 1.38889vw 1.38889vw 4.16667vw; }
    [dir="rtl"] .cpcs-esis_story-telling-content-area-inner { padding:  4.16667vw 4.16667vw 1.38889vw 1.38889vw; }
    .cpcs-esis_story-telling-content-title { padding: 0.69444vw 0; }
    .cpcs-esis_story-telling-content-subtitle { padding: 0.69444vw 0 0; }
    .cpcs-esis_story-telling-content-description { padding: 0.69444vw 0; }
    .cpcs-esis_story-telling-content-disclaimer { padding: 1.38889vw 0 0.69444vw; }
    .cpcs-esis_story-telling-content-cta { padding: 1.04167vw 0; }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cpcs-esis-section-wrapper.cpcs-esis_story-telling  .cpcs-esis-section-inner { padding: 0 !important;}
    /* .cpcs-esis-section-wrapper.cpcs-esis_story-telling:last-child .cpcs-esis_story-telling-listing  { padding:0 0 4.86111vw 0; }
    .cpcs-esis_story-telling .cpcs-esis_story-telling-listing.cpcs-esis_story-telling-first-item  { padding: 4.86111vw 0 0; } */
    .cpcs-esis_story-telling-content-area-inner { padding: 4.16667vw 1.38889vw 1.38889vw 4.16667vw; }
    [dir="rtl"] .cpcs-esis_story-telling-content-area-inner { padding:  4.16667vw 4.16667vw 1.38889vw 1.38889vw; }
    .cpcs-esis_story-telling-content-title { padding: 0.69444vw 0; }
    .cpcs-esis_story-telling-content-subtitle { padding: 0.69444vw 0 0; }
    .cpcs-esis_story-telling-content-description { padding: 0.69444vw 0; }
    .cpcs-esis_story-telling-content-disclaimer { padding: 1.38889vw 0 0.69444vw; }
    .cpcs-esis_story-telling-content-cta { padding: 1.04167vw 0; }
}

@media (max-width: 767px) {
    .cpcs-esis-section-wrapper.cpcs-esis_story-telling:last-child .cpcs-esis_story-telling-listing, .cpcs-esis-section-wrapper.cpcs-esis_story-telling:first-child  .cpcs-esis_story-telling-listing   { padding: 0; }  
    .cpcs-esis_story-telling-row { padding: 5.55556vw 0; }
    .cpcs-esis_story-telling-col { width: 100%; }    
    .cpcs-esis_story-telling-content-area-inner,  [dir="rtl"] .cpcs-esis_story-telling-content-area-inner  { padding: 2.77778vw; text-align:center }
    .cpcs-esis_story-telling-content-title { padding: 3.77778vw 0 0; }
    .cpcs-esis_story-telling-content-subtitle { padding: 3.77778vw 0 0; }
    .cpcs-esis_story-telling-content-subtitle .text-lg{margin: 0!important;}
    .cpcs-esis_story-telling-content-description { padding: 5.66666vw 0 2.11111vw; }
    .cpcs-esis_story-telling-content-disclaimer { padding: 3.77778vw 0 3.77778vw; }
    .cpcs-esis_story-telling-content-cta { padding: 3.77778vw 0; }
    .cpcs-esis_story-telling .cpcs-esis_story-telling-listing.cpcs-esis_story-telling-first-item  {  padding: 9.44444vw 0 0;}
}