/* Component - Home KV Carousel */
.cpcs-esis_home-kv-carousel-wrapper .section-inner { width: 100%; max-width: 1440px; margin: 0 auto; padding: 0; }
.cpcs-esis_home-kv-carousel { width: 100%; margin: 0; padding: 0; }
.cpcs-esis_home-kv-carousel-item { width: 100%; margin: 0; padding: 0; position: relative; }
.cpcs-esis_home-kv-carousel-figure { width: 100%; margin: 0; padding: 0; }
.cpcs-esis_home-kv-carousel-figure .img-wrapper .desktop-only { display: block;}
.cpcs-esis_home-kv-carousel-figure .img-wrapper .mobile-only { display: none;}
.cpcs-esis_home-kv-carousel-content-area { width: 50%; margin: 0; position: absolute; z-index: 1; text-align: left; }
[dir="rtl"] .cpcs-esis_home-kv-carousel-content-area {  text-align: right; }
.cpcs-esis_home-kv-carousel-content-inner { padding: 30px 60px; }
.cpcs-esis_home-kv-carousel-content-sub-title h4  { padding: 40px 0 0; }
.cpcs-esis_home-kv-carousel-content-desc { padding: 40px 0 0; }
.cpcs-esis_home-kv-carousel-content-desc-sm { padding: 15px 0 0; }
.cpcs-esis_home-kv-carousel-content-ctas {padding: 40px 0 0 ;}
.cpcs-esis_home-kv-carousel-content-ctas a.cta { margin-right: 16px; }
[dir="rtl"] .cpcs-esis_home-kv-carousel-content-ctas a.cta { margin-left: 16px; }
.cpcs-esis_home-kv-carousel-content-ctas a.cta:last-child { margin-right: 0; }
[dir="rtl"] .cpcs-esis_home-kv-carousel-content-ctas a.cta:last-child { margin-left: 0; }
.cpcs-esis_home-kv-carousel-content-ctas a.cta.cta-link { border-bottom-width: 2px; }

@media (min-width:768px) and (max-width: 1440px) {
    .cpcs-esis_home-kv-carousel-content-inner { padding: 1.333333vw 2.666667vw; }
    .cpcs-esis_home-kv-carousel-content-sub-title h4  { padding:2.77778vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-desc { padding: 2.77778vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-desc-sm { padding: 0.333333vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-ctas { padding: 2.77778vw 0 0 ;}
    .cpcs-esis_home-kv-carousel-content-ctas ul li { font-size: 1.333333vw; margin-bottom: 1vw; }
}

@media (max-width: 767px) {
    .cpcs-esis_home-kv-carousel-figure .img-wrapper .desktop-only { display: none;}
    .cpcs-esis_home-kv-carousel-figure .img-wrapper .mobile-only { display: block;}
    .cpcs-esis_home-kv-carousel-content-area { width: 100% !important; text-align: center; }
    [dir="rtl"] .cpcs-esis_home-kv-carousel-content-area { margin: 0 auto;  text-align: center;}
    .cpcs-esis_home-kv-carousel-content-inner { padding: 7.25vw 3.5125vw; }
    .cpcs-esis_home-kv-carousel-content-sub-title h4  { padding: 5.44444444vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-desc { padding: 5.44444444vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-desc-sm { padding: 5.44444444vw 0 0; }
    .cpcs-esis_home-kv-carousel-content-ctas { padding: 5.44444444vw 0 0 ;}

}
