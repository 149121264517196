.cpcs-esis_catagories-footer-title { padding: 0 20px 20px; }
.cpcs-esis_catagories-footer-container { padding-top: 40px; }
.cpcs-esis_catagories-footer-item { display: inline-block; padding: 0 15px; text-align: left; width: 24%; vertical-align: top; }
[dir="rtl"] .cpcs-esis_catagories-footer-item { display: inline-block; padding: 0 15px; text-align: right; width: 24%; vertical-align: top; }
.cpcs-esis-catagories-footer-item-title { font-weight: bold; text-transform: uppercase; }
.cpcs-esis-catagories-footer-item-title p { font-size: 16px; }
.cpcs-esis-catagories-footer-links { padding: 20px 0 0 0; }
.cpcs-esis-catagories-footer-links ul { list-style: none; padding: 0; margin: 0; }
.cpcs-esis-catagories-footer-links ul li { padding: 0 0 20px; }
.cpcs-esis-catagories-footer-subitem-title a p{ color: #000; text-decoration: none; font-weight: 400; }
.category-footer-item ul li a.cta.cta-link, .category-footer-item ul li a.cta.cta-link:hover, .category-footer-item ul li a.cta.cta-link:focus, .category-footer-item ul li a.cta.cta-link:active { border-bottom-width: 2px !important; }

@media (min-width: 1025px) and (max-width: 1440px) {
    .cpcs-esis_catagories-footer-title { padding: 0 1.38889vw 1.38889vw; }
    .cpcs-esis_catagories-footer-container { padding-top: 2.77778vw; }
    .cpcs-esis_catagories-footer-item { padding: 0 1.04167vw; }
    .cpcs-esis-catagories-footer-item-title p { font-size: 1.11111vw; }
    .cpcs-esis-catagories-footer-links { padding: 1.38889vw 0 0 0; }
    .cpcs-esis-catagories-footer-links ul li { padding: 0 0 1.38889vw; }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cpcs-esis_catagories-footer-title { padding: 0 1.38889vw 1.38889vw; }
    .cpcs-esis_catagories-footer-container { padding-top: 2.77778vw; }
    .cpcs-esis_catagories-footer-item { padding: 0 1.04167vw; }
    .cpcs-esis-catagories-footer-item-title p { font-size: 1.11111vw; }
    .cpcs-esis-catagories-footer-links { padding: 1.38889vw 0 0 0; }
    .cpcs-esis-catagories-footer-links ul li { padding: 0 0 1.38889vw; }
}

@media (max-width: 767px) {
    .cpcs-esis_catagories-footer-title { padding: 0 4.166667vw 4.166667vw; }
    .cpcs-esis_catagories-footer-container { padding-top: 5.555556vw; }
    .cpcs-esis_catagories-footer-item { width: 100%; padding: 0 5.555556vw; }
    [dir="rtl"] .cpcs-esis_catagories-footer-item { width: 100%; padding: 0 5.555556vw; }
    .cpcs-esis-catagories-footer-item-title { padding: 4.166667vw 0 2.77778vw; }
    .cpcs-esis-catagories-footer-item-title p { font-size: 3.88889vw; }
    .cpcs-esis-catagories-footer-links { padding: 2.77778vw 0 0; }
    .cpcs-esis-catagories-footer-links ul li { padding: 0 0 4.666667vw; }
}