/* Component - Key Reviews */
.cpcs-esis_key-reviews-container { width: 100%; margin: 0; padding: 0; padding-top: 40px; }
.cpcs-esis_key-reviews-block { width: 100%; margin: 0; padding: 0; }
.cpcs-esis_key-reviews-item { width: 32.5%; display: inline-block; margin: 0; padding: 0; vertical-align: top; }
.cpcs-esis_key-reviews-item-inner { position: relative; padding: 0 50px 0; }
.cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-rating { padding-top: 20px; }
.cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-product { padding-top: 20px; }
.cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-quote { padding: 10px 0; } 
.cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-author { padding-top: 5px; }
.cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-inner::after { content: ''; position: absolute; right: 0; top: 20%; height: 130px; bottom: 0; width: 2px; background-color: #ccc; overflow: hidden; } 
[dir="rtl"] .cpcs-esis_key-reviews-item-inner::after { content: ''; position: absolute; left: 0 !important; right: auto; top: 20%; height: 130px; bottom: 0; width: 2px; background-color: #ccc; overflow: hidden; } 
.cpcs-esis_key-reviews-item:last-child .cpcs-esis_key-reviews-item-inner:after { content: ''; position: relative; right: 0; top: 0; bottom: 0; width: 0; background-color: transparent; overflow: hidden; } 
[dir="rtl"] .cpcs-esis_key-reviews-item:last-child .cpcs-esis_key-reviews-item-inner:after { content: ''; position: relative; left: 0; top: 0; bottom: 0; width: 0; background-color: transparent; overflow: hidden; } 

@media (min-width:768px) and (max-width: 1440px) { 
    .cpcs-esis_key-reviews-container { padding-top:2.77778vw; }
    .cpcs-esis_key-reviews-item-inner { padding: 0 3.5vw 0; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-quote { padding: 0.69444vw 0; } 
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-inner::after { height: 10.45vw; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-rating { padding-top: 1.4vw; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-product { padding-top: 1.4vw; }
}

@media (max-width: 767px) { 
    .cpcs-esis_key-reviews-container { padding-top: 5.55556vw; }
    .cpcs-esis_key-reviews-item { width: 100%;  }
    .cpcs-esis_key-reviews-item-inner { padding: 3.333333vw 3.333333vw; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-quote { padding: 1.38889vw 0;} 
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-rating { padding-top: 2.5vw; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-product { padding-top: 2.5vw; }
    .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-inner::after { content: ''; position: absolute; right: 20%; top: auto; width: 60%; bottom: 0; height: 2px; } 
    [dir="rtl"] .cpcs-esis_key-reviews-item .cpcs-esis_key-reviews-item-inner::after { content: ''; position: absolute; left: 20% !important; right: auto; top: auto; width: 60%; bottom: 0; height: 2px; } 
}