.cpcs-esis_catagories-mst-title { padding: 0 40px 40px; }
.cpcs-esis_catagories-mst-list { width: 100%;  margin: 0 auto; padding: 0; }
.cpcs-esis_catagories-mst-list-inner { display: flex; align-items: center; justify-content:center; flex-wrap: wrap;}
.cpcs-esis_catagories-mst-item { display: inline-block; }
.cpcs-esis_catagories-mst-item-inner { display: block; cursor: pointer; padding: 14px; text-decoration: none; }
.cpcs-esis_catagories-mst-item-icon { padding: 0 0 10px; max-width: 100px; margin: 0 auto; }
.cpcs-esis_catagories-mst-item-label { padding: 10px 0; }
.cpcs-esis_catagories-mst-item-label-inner { font-family: 'SamsungSharpSans'; font-weight: 700; font-size: 16px; line-height: 1.33; color: #000000; }
[dir="rtl"] .cpcs-esis_catagories-mst-item-label-inner { font-family: 'SamsungSharpSans','SamsungOneArabic';  font-family: 700;}

@media (min-width: 1025px) and (max-width: 1440px) {
    .cpcs-esis_catagories-mst-title { padding: 0 2.77778vw 2.77778vw; }
    .cpcs-esis_catagories-mst-item-inner { padding: 0 0.69444vw; }
    .cpcs-esis_catagories-mst-item-icon { padding: 0 0 0.69444vw; max-width: 6.25vw; }
    .cpcs-esis_catagories-mst-item-label { padding: 0.69444vw 0; }
    .cpcs-esis_catagories-mst-item-label-inner { font-size: 1.11111vw; }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cpcs-esis_catagories-mst-title { padding: 0 2.77778vw 2.77778vw; }
    .cpcs-esis_catagories-mst-item-inner { padding: 0 0.69444vw; }
    .cpcs-esis_catagories-mst-item-icon { padding: 0 0 0.69444vw; max-width: 7.98611vw; }
    .cpcs-esis_catagories-mst-item-label { padding: 0.69444vw 0; }
    .cpcs-esis_catagories-mst-item-label-inner { font-size: 1.25vw; }
}

@media (max-width: 767px) {
    .cpcs-esis_catagories-mst-inner { padding: 9.44444vw 0; }
    .cpcs-esis_catagories-mst-title { padding: 0  2.77778vw  5.555556vw; }
    .cpcs-esis_catagories-mst-list { padding: 2.77778vw 0 0 0; }
    .cpcs-esis_catagories-mst-list-inner { padding: 0; display: block; }
    .cpcs-esis_catagories-mst-item { width: 49%; padding: 2.77778vw; }
    .cpcs-esis_catagories-mst-item-inner { padding: 0 1.38889vw; }
    .cpcs-esis_catagories-mst-item-icon { padding: 0 0 1.38889vw; display: none; max-width: auto; }
    .cpcs-esis_catagories-mst-item-label { padding: 1.38889vw 0; }
    .cpcs-esis_catagories-mst-item-label-inner { font-size: 3.3333vw; }
}