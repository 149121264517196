/* Component - KV General */
.cpcs-esis_home-general-kv { width: 100%; margin: 0; padding: 0; position: relative; }
.cpcs-esis_kv-general-content-area { margin: 0; position: absolute; z-index: 1; text-align: left; }
[dir="rtl"] .cpcs-esis_kv-general-content-area { margin: 0; position: absolute; z-index: 1; text-align: right; }
.cpcs-esis_kv-general-content-inner { padding: 30px 60px; }
.cpcs-esis_kv-general-content-desc, .cpcs-esis_overlay-content-btns { padding: 25px 0 0; }
 

@media (min-width:768px) and (max-width: 1440px) {
    .cpcs-esis_kv-general-content-inner { padding: 1.333333vw 2.666667vw; }
    .cpcs-esis_kv-general-content-desc, .cpcs-esis_overlay-content-btns { padding: 1.98413vw 0 0; }   
}

@media (max-width: 767px) {
    .cpcs-esis_kv-general-content-area { width: 100% !important; text-align: center; }
    [dir="rtl"] .cpcs-esis_kv-general-content-area { margin: 0; position: absolute; z-index: 1; text-align: center; }
    .cpcs-esis_kv-general-content-inner { padding: 30px 15px; }
    .cpcs-esis_kv-general-content-desc, .cpcs-esis_overlay-content-btns { padding: 5.55556vw 0 0; }
}
