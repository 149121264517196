/* Component - Collection with text and image */
.cpcs-esis_collection-text-img-row { width: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; font-size: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
.cpcs-esis_collection-text-img-col { display: inline-block; -webkit-box-sizing: border-box; box-sizing: border-box; margin: 0; font-size: 0; }
.cpcs-esis_collection-text-img-col.content-container { background-color: #166bff; color: #ffffff; }
.cpcs-esis_collection-text-img-content-area .content-area-inner { padding: 100px 100px 0; text-align: left; }
[dir="rtl"] .cpcs-esis_collection-text-img-content-area .content-area-inner { padding: 100px 100px 0 ; text-align: right; }
.cpcs-esis_collection-text-img-content-area .content-desc { padding: 0 0 10px; }
.cpcs-esis_collection-bundle-container { width: 100%;  margin: 0 auto; }
.cpcs-esis_collection-bundle-listing-inner {display: flex; align-items: stretch; justify-content: center; flex-wrap: nowrap; width: 100%;}
.cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item {  width: 100%; padding: 0 4px 0 0;}
[dir="rtl"] .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item { width: 100%; padding: 0 0 0 4px;}

.cpcs-esis_collection-bundle-welcome { width: 100%; margin: 0; padding: 0; position: relative;}
.cpcs-esis_collection-bundle-welcome-bg { width: 100%; margin: 0; padding: 0; }
.cpcs-esis_collection-bundle-welcome-overlay{ position: absolute;  text-align: left; }
[dir="rtl"] .cpcs-esis_collection-bundle-welcome-overlay{ position: absolute;  text-align: right; }
.cpcs-esis_collection-bundle-welcome-overlay-content { padding: 20px;}
.cpcs-esis_overlay-content-desc { padding:  40px 0 0;}
.cpcs-esis_overlay-content-btns { display: flex; align-items: center; justify-content: flex-start;  flex-wrap: wrap;}
.cpcs-esis_overlay-content-btn-item {flex: 50%; padding: 5px 0;}


@media (min-width: 1441px) { 
    .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item:last-child { padding: 0;}
    [dir="rtl"] .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item:last-child { padding: 0;}
}
@media (min-width:768px) and (max-width: 1440px) {
    .cpcs-esis_overlay-content-desc { padding:  2.77778vw 0 0;}
    .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item { padding-right: 0 0.27778vw 0 0;}
    [dir="rtl"] .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item { padding-left: 0 0 0 0.27778vw;}
    .cmp-collection-text-img { padding: 4.333333vw 0; }
    .cpcs-esis_collection-text-img-content-area .content-area-inner { padding: 6.85vw 6.85vw 0 6.85vw; }
}

@media (max-width: 767px) { 
    .cpcs-esis_collection-bundle-listing-inner {align-items: stretch; flex-wrap: wrap;}
    .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item { padding: 0.555556vw;}
    [dir="rtl"] .cpcs-esis_collection-bundle-container .cpcs-esis_featured-collection-item { padding: 0.555556vw;}
    .cmp-collection-text-img { padding: 8.666667vw 0; }  
    .cpcs-esis_collection-text-img-col, .cpcs-esis_collection-text-img-col.width-short, .cpcs-esis_collection-text-img-col.width-long, .cpcs-esis_collection-text-img-col.width-short-tv, .cpcs-esis_collection-text-img-col.width-long-tv { width: 100%; }
    .cpcs-esis_collection-text-img-content-area .content-area-inner { padding: 4.75vw; }    
    .cpcs-esis_collection-bundle-welcome-overlay { position: relative; width: 100%; top: auto; left: auto; background-color: #166bff; color: #ffffff; padding: 4.75vw; }
    [dir="rtl"] .cpcs-esis_collection-bundle-welcome-overlay { position: relative; width: 100%; top: auto; right: auto; background-color: #166bff; color: #ffffff; padding: 4.75vw; text-align: right !important; }    
    .cta-general-kv p { font-size: 3.5vw !important;}
    .cpcs-esis_overlay-content-desc { padding: 5.66667vw 0 0;}
}