.cpcs-esis_shopby-container { width: 100%; max-width: 1220px; margin: 0 auto;}
.cpcs-esis_shopby-title { padding: 0 20px; }
.cpcs-esis_shopby-listing { padding: 40px 0 0; }
.cpcs-esis_shopby-listing-inner { display: flex; align-items: flex-start; justify-content: center; flex-wrap: nowrap;}
.cpcs-esis_shopby-item { flex: 1 1 0; display: inline-block; padding: 0 20px; vertical-align: top; max-width: 21%;}
.cpcs-esis_shopby-item-title-inner { padding: 10px; }
.cpcs-esis_shopby-item a.cta-selector { color: #000; text-decoration: none; }

@media (min-width: 1025px) and (max-width: 1440px) {
    .cpcs-esis_shopby-title { padding: 0 1.38889vw; }
    .cpcs-esis_shopby-listing { padding: 2.77778vw 0 0; }
    .cpcs-esis_shopby-item { padding: 0 1.38889vw; }
    .cpcs-esis_shopby-item-title-inner { padding: 0.69444vw; }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cpcs-esis_shopby-title { padding: 0 1.38889vw; }
    .cpcs-esis_shopby-listing { padding: 2.77778vw 0 0; }
    .cpcs-esis_shopby-item { padding: 0 1.38889vw; }
    .cpcs-esis_shopby-item-title-inner { padding: 0.69444vw; }
}

@media (max-width: 767px) {
    .cpcs-esis_shopby-title { padding: 0 2.77778vw 2.77778vw; }
    .cpcs-esis_shopby-listing { padding: 5.55556vw 0 0; }
    .cpcs-esis_shopby-listing-inner {align-items: stretch; flex-wrap: wrap;}
    .cpcs-esis_shopby-item {flex-basis: 50%; margin:0.69444vw; padding: 0.69444vw; max-width: 48%; border: 2px solid #f5f5f5; display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap;}
    .cpcs-esis_shopby-item-inner { padding: 0 1vw; margin-bottom: 0.5vw;  }
    .cpcs-esis_shopby-item-img { display: none; }
    .cpcs-esis_shopby-item-title-inner { padding: 5vw 0; }
}