.cpcs-esis_banner_half_tiles_wrapper { width: 100%; margin: 0 auto; padding: 0; display: grid; grid-template-columns: 49.21% 49.21%; grid-auto-flow: dense;  gap: 20px; overflow: hidden; height: 620px;}
.cpcs-esis_banner-product_big-half_tile { width: 100%; height: 620px;}
.cpcs-esis_banner-product_small-half_tile {width: 100%; height: 300px;}

@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis_banner_half_tiles_wrapper { gap: 1.38889vw;  height: 43.05556vw;}
  .cpcs-esis_banner-product_big-half_tile { height: 43.05556vw; }
  .cpcs-esis_banner-product_small-half_tile { height: 20.83333vw; }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis_banner_half_tiles_wrapper { gap: 1.38889vw;  height: 43.05556vw;}
  .cpcs-esis_banner-product_big-half_tile { height: 43.05556vw; }
  .cpcs-esis_banner-product_small-half_tile { height: 20.83333vw; }
}

@media (max-width: 767px) {
  .cpcs-esis_banner_half_tiles_wrapper { display: flex;  flex-direction: column; gap: 5.55556vw; height: 257.4228vw; }
  .cpcs-esis_banner-product_big-half_tile { height: 150vw;}
  .cpcs-esis_banner-product_small-half_tile { padding: 0; height:48.1558vw; }
}
