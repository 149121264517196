.cpcs-esis_featured-collection-container { width: 100%; margin: 0 auto; padding: 20px 0px; }
.cpcs-esis_featured-collection-listing-inner {display: flex; align-items: stretch; justify-content: center; flex-wrap: nowrap; }
.cpcs-esis_featured-collection-title { padding: 0 20px 20px; }
.cpcs-esis_featured-collection-container .cpcs-esis_featured-collection-item { padding: 0 10px;}
.cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item { padding-left: 0px;}
.cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item {padding-right: 0px;}
[dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item{ padding-right: 0px;  padding-left: 10px;}
[dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item{padding-left: 0px; padding-right: 10px;}
.cpcs-esis_featured-collection-listing-inner-smaller .cpcs-esis_featured-collection-item {max-width: 25%;}
.cpcs-esis_featured-collection-item.cpcs-esis_best-product-item { max-height: 395px; }

.cpcs-esis_featured-collection-item { flex-basis: 100%; display: inline-block;}
.cpcs-esis_featured-collection-item-inner {width: 100%; height: 100%; padding: 10px 0 20px; background-color: #fff; border: 2px solid #f5f5f5;  display: flex; align-items: center; justify-content: space-between; flex-direction: column; text-align: center; gap:5px}
.cpcs-esis_featured-collection-item-img { width: 280px; height:180px; }
.cpcs-esis_featured-collection-item-title {  display: flex; align-items: center; flex-wrap: wrap; }
.cpcs-esis_featured-collection-item-price-container { width:  100%; margin: 0 auto; padding: 10px 0;}
.cpcs-esis_featured-collection-item-sale-price { font-weight: bold; }
.cpcs-esis_featured-collection-item-actual-price-diff{ width: 100%; font-family: 'SamsungOne'; font-size: 12px !important; color: #006bea; margin-top: 5px;  font-weight: bold; }
[dir="rtl"] .cpcs-esis_featured-collection-item-actual-price-diff{   width: 100%;  font-family: 'SamsungOneArabic'; font-size: 12px !important; color: #006bea;margin-top: 5px; font-weight: bold; }
.cpcs-esis_featured-collection-item-actual-price { text-decoration: line-through; color: black; font-weight: normal;  margin-right: 5px}
[dir="rtl"] .cpcs-esis_featured-collection-item-actual-price { margin-left: 5px }
.cpcs-esis_best-product-item .cpcs-esis_featured-collection-item-title  { max-height: 80px; min-height: 30px; height: 100%;}
.cpcs-esis_featured-collection-item-title-inner { padding:0 10px; display: -webkit-box; -webkit-line-clamp: 3;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; }

@media (min-width: 1025px) and (max-width: 1440px) {
    .cpcs-esis_featured-collection-container { padding: 1.38889vw 0; }
    .cpcs-esis_featured-collection-title { padding: 0 1.38889vw 1.38889vw; }
    .cpcs-esis_featured-collection-container .cpcs-esis_featured-collection-item { padding: 0 0.69444vw; }
    .cpcs-esis_featured-collection-item.cpcs-esis_best-product-item { max-height: 31vw; }
    .cpcs-esis_featured-collection-item-inner { padding: 0.69444vw 0.69444vw 1.38889vw; gap:0.34722vw;}
    .cpcs-esis_featured-collection-item-img { width: 19.44444vw; height:12.50000vw; }
    .cpcs-esis_best-product-item .cpcs-esis_featured-collection-item-title  { max-height: 5.55556vw; min-height: 2.08333vw; }
    .cpcs-esis_featured-collection-item-price-container { padding: 0.69444vw 0;}
    .cpcs-esis_featured-collection-item-actual-price-diff{ font-size: 0.83333vw !important; margin-top: 0.34722vw; }
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price-diff{  font-size: 0.83333vw !important; margin-top: 0.34722vw;}
    .cpcs-esis_featured-collection-item-actual-price {   margin-right: 0.34722vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price { margin-left: 0.34722vw; }
    .cpcs-esis_featured-collection-item-title-inner { padding:0 0.69444vw; }
    [dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item{ padding-left: 0.69444vw;}
[dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item{ padding-right: 0.69444vw;}

}

@media (min-width: 768px) and (max-width: 1024px) {
    .cpcs-esis_featured-collection-container { padding: 1.38889vw 0; }
    .cpcs-esis_featured-collection-title { padding: 0 1.38889vw 1.38889vw; }
    .cpcs-esis_featured-collection-item.cpcs-esis_best-product-item { max-height: 31vw; }
    .cpcs-esis_featured-collection-container .cpcs-esis_featured-collection-item { padding: 0 0.69444vw; }
    .cpcs-esis_featured-collection-item-inner { padding: 0.69444vw 0.69444vw 1.38889vw; gap:0.34722vw;}
    .cpcs-esis_featured-collection-item-img { width: 19.44444vw; height:12.50000vw; }
    .cpcs-esis_best-product-item .cpcs-esis_featured-collection-item-title  { max-height: 5.55556vw; min-height: 2.08333vw;}
    .cpcs-esis_featured-collection-item-title-inner { padding:0 0.69444vw; }
    .cpcs-esis_featured-collection-item-price-container { padding: 0.69444vw 0;}
    .cpcs-esis_featured-collection-item-actual-price-diff{ font-size: 0.83333vw !important; margin-top: 0.34722vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price-diff{  font-size: 0.83333vw !important; margin-top: 0.34722vw;}
    .cpcs-esis_featured-collection-item-actual-price {   margin-right: 0.34722vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price { margin-left: 0.34722vw; }
    [dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item{ padding-left: 0.69444vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item{ padding-right: 0.69444vw;}
}

@media (max-width: 767px) {
    .cpcs-esis_featured-collection-container { padding: 5.55556vw 0; }
    .cpcs-esis_featured-collection-title { padding: 0 2.77778vw 5.55556vw; }
    .cpcs-esis_featured-collection-listing-inner { align-items: stretch; flex-wrap: wrap;}
    .cpcs-esis_featured-collection-item.cpcs-esis_best-product-item { max-height: 100%;}
    .cpcs-esis_featured-collection-container .cpcs-esis_featured-collection-item { padding: 0.555556vw; }
    .cpcs-esis_featured-collection-listing-inner-smaller .cpcs-esis_featured-collection-item { max-width: 100%; }
    .cpcs-esis_featured-collection-listing-inner-smaller { justify-content: flex-start;}
    .cpcs-esis_featured-collection-item { flex-basis:50%;}
    .cpcs-esis_featured-collection-item-inner { padding: 1.38889vw 1.38889vw 3.88889vw; gap:1.38889vw;}
    .cpcs-esis_featured-collection-item-img {  width: 42.77778vw; height:33.33333vw; }
    .cpcs-esis_featured-collection-item-price-container { padding: 2.77778vw 0;}
    .cpcs-esis_featured-collection-item-actual-price-diff{ font-size: 2.9998vw !important; margin-top:0.555556vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price-diff{ font-size: 2.9998vw !important; margin-top:0.555556vw;}
    .cpcs-esis_featured-collection-item-actual-price { margin-right: 0.555556vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-item-actual-price { margin-left:  0.555556vw; }
    .cpcs-esis_featured-collection-item-title-inner { padding:0 1.38889vw; }
    .cpcs-esis_best-product-item .cpcs-esis_featured-collection-item-title  { max-height: 11.11111vw; min-height: 8.333333vw;}
    .cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item{ padding-left:  0.555556vw; padding-right: 0.555556vw;}
    .cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item{padding-right: 0.555556vw; padding-left: 0.555556vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:first-child .cpcs-esis_featured-collection-item{padding-right: 0.555556vw; padding-left: 0.555556vw;}
    [dir="rtl"] .cpcs-esis_featured-collection-container .swiper-slide:last-child .cpcs-esis_featured-collection-item{ padding-left:  0.555556vw; padding-right: 0.555556vw;}
   
}