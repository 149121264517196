@charset "utf-8";

/* Font Family */
@font-face { font-display: swap; font-family: 'SamsungOne'; font-style: normal; font-weight: normal; src: local('SamsungOne'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-400.woff2') format('woff2'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-400.woff') format('woff'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-400.ttf') format('truetype'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-400.eot?#iefix') format('embedded-opentype') }
@font-face { font-display: swap; font-family: 'SamsungOne'; font-style: normal; font-weight: bold; src: local('SamsungOne'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-700.woff2') format('woff2'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-700.woff') format('woff'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-700.ttf') format('truetype'), url('https://media.samsungepartners.com/common/fonts/en/SamsungOne-700.eot?#iefix') format('embedded-opentype') }
@font-face { font-display: swap; font-family: 'SamsungSharpSans'; font-style: normal; font-weight: bold; src: local('SamsungSharpSans'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.woff2') format('woff2'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.woff') format('woff'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype') }
@font-face { font-display: swap; font-family: 'SamsungSharpSans'; font-style: normal; font-weight: normal; src: local('SamsungSharpSans'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.woff2') format('woff2'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.woff') format('woff'), url('https://media.samsungepartners.com/common/fonts/en/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype') }
/* arabic */
@font-face{font-display:swap;font-family:'SamsungOneArabic';font-style:normal;font-weight:400;src:local("SamsungOneArabic"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-400.woff2') format("woff2"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-400.woff') format("woff"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-400.ttf') format("truetype")}
@font-face{font-display:swap;font-family:'SamsungOneArabic';font-style:normal;font-weight:700;src:local("SamsungOneArabic"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-700.woff2') format("woff2"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-700.woff') format("woff"),url('https://media.samsungepartners.com/common/fonts/ar/SamsungOneArabic-700.ttf') format("truetype")}
@font-face{font-display:swap;font-family:'SamsungSharpSans';font-style:normal;font-weight:bold;src:local('SamsungSharpSans'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.woff2') format('woff2'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.woff') format('woff'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')}					
@font-face{font-display:swap;font-family:'SamsungSharpSans';font-style:normal;font-weight:normal;src:local('SamsungSharpSans'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.woff2') format('woff2'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.woff') format('woff'),url('https://media.samsungepartners.com/common/fonts/ar/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype')}					


body { margin: 0; }
.nav-open { position: fixed; width: 100%; top: 0px;}
/* Common Typography */
.cpcs-esis * { box-sizing: border-box; }
.cpcs-esis h1 { font-family: 'SamsungSharpSans'; font-size: 52px; line-height: 1.2; margin: 0; padding: 0; font-weight: 700 ; }
[dir="rtl"] .cpcs-esis h1 { font-family: 'SamsungSharpSans','SamsungOneArabic';   font-weight: 700 ;}
.cpcs-esis h2 { font-family: 'SamsungSharpSans'; font-size: 42px; line-height: 1.2; margin: 0; padding: 0; font-weight: 700 ; }
[dir="rtl"] .cpcs-esis h2 { font-family: 'SamsungSharpSans','SamsungOneArabic';   font-weight: 700 ;}
.cpcs-esis h3 { font-family: 'SamsungSharpSans'; font-size: 28px; line-height: 1.2; margin: 0; padding: 0;  font-weight: 700 ; }
[dir="rtl"] .cpcs-esis h3 { font-family: 'SamsungSharpSans','SamsungOneArabic';  font-weight: 700 ; }
.cpcs-esis h4 { font-family: 'SamsungSharpSans'; font-size: 22px; line-height: 1.2; margin: 0; padding: 0; font-weight: 700 ; }
[dir="rtl"] .cpcs-esis h4 { font-family: 'SamsungSharpSans','SamsungOneArabic';  font-weight: 700 ; }
.cpcs-esis h5 { font-family: 'SamsungSharpSans'; font-size: 18px; line-height: 1.2; margin: 0; padding: 0;  font-weight: 700 ;}
[dir="rtl"] .cpcs-esis h5 { font-family: 'SamsungSharpSans','SamsungOneArabic';  font-weight: 700 ;}
.cpcs-esis h6 { font-family: 'SamsungSharpSans'; font-size: 14px; line-height: 1.2; margin: 0; padding: 0;  font-weight: 700 ;}
[dir="rtl"] .cpcs-esis h6 { font-family: 'SamsungSharpSans','SamsungOneArabic';  font-weight: 700 ; }
.cpcs-esis p { font-family: 'SamsungOne'; font-size: 16px; line-height: 1.4; margin: 0; padding: 0; }
[dir="rtl"] .cpcs-esis p { font-family: 'SamsungOneArabic'; }
.cpcs-esis p.text-sm { font-family: 'SamsungOne'; font-size: 12px; line-height: 1.4; margin: 0; padding: 0;font-weight:700; }
[dir="rtl"] .cpcs-esis p.text-sm { font-family: 'SamsungOneArabic';  }
.cpcs-esis p.text-heavy { font-family: 'SamsungSharpSans'; font-weight: normal; }
[dir="rtl"] .cpcs-esis p.text-heavy { font-family:  'SamsungSharpSans','SamsungOneArabic'; font-weight:700; }

.cpcs-esis .text-gray { color: #777; }
.cpcs-esis .img-wrapper img,
.cpcs-esis .icon-wrapper img { max-width: 100%; max-height: 100%; margin-left: auto; margin-right: auto; }
.cpcs-esis .img-wrapper,
.cpcs-esis .icon-wrapper { width: 100%; /* height: 100%; */}
.cpcs-esis .desktop-only { display: block; }
.cpcs-esis .mobile-only { display: none; }
/* Spacing */
.py-xl-dkp { padding-top: 80px !important; padding-bottom: 80px !important; }
.py-lg-dkp { padding-top: 60px !important; padding-bottom: 60px !important; }
.py-md-dkp { padding-top: 40px !important; padding-bottom: 40px !important; }
.py-sm-dkp { padding-top: 20px !important; padding-bottom: 20px !important; }
.py-xs-dkp { padding-top: 10px !important; padding-bottom: 10px !important; }
.py-no-dkp { padding-top: 0 !important; padding-bottom: 0 !important; }
.px-xl-dkp { padding-left: 80px !important; padding-right: 80px !important; }
.px-lg-dkp { padding-left: 60px !important; padding-right: 60px !important; }
.px-md-dkp { padding-left: 40px !important; padding-right: 40px !important; }
.px-sm-dkp { padding-left: 20px !important; padding-right: 20px !important; }
.px-xs-dkp { padding-left: 10px !important; padding-right: 10px !important; }
.px-no-dkp { padding-left: 0 !important; padding-right: 0 !important; }
.pt-xl-dkp { padding-top: 80px !important; }
.pt-lg-dkp { padding-top: 60px !important; }
.pt-md-dkp { padding-top: 40px !important; }
.pt-sm-dkp { padding-top: 20px !important; }
.pt-xs-dkp { padding-top: 10px !important; }
.pt-no-dkp { padding-top: 0 !important; }
.pb-xl-dkp { padding-bottom: 80px !important; }
.pb-lg-dkp { padding-bottom: 60px !important; }
.pb-md-dkp { padding-bottom: 40px !important; }
.pb-sm-dkp { padding-bottom: 20px !important; }
.pb-xs-dkp { padding-bottom: 10px !important; }
.pb-no-dkp { padding-bottom: 0 !important; }
.pl-xl-dkp { padding-left: 80px !important; }
.pl-lg-dkp { padding-left: 60px !important; }
.pl-md-dkp { padding-left: 40px !important; }
.pl-sm-dkp { padding-left: 20px !important; }
.pl-xs-dkp { padding-left: 10px !important; }
.pl-no-dkp { padding-left: 0 !important; }
.pr-xl-dkp { padding-right: 80px !important; }
.pr-lg-dkp { padding-right: 60px !important; }
.pr-md-dkp { padding-right: 40px !important; }
.pr-sm-dkp { padding-right: 20px !important; }
.pr-xs-dkp { padding-right: 10px !important; }
.pr-no-dkp { padding-right: 0 !important; }

@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis h1 { font-size: 3.61111vw; }
  .cpcs-esis h2 { font-size: 2.91667vw; }
  .cpcs-esis h3 { font-size:1.94444vw; }
  .cpcs-esis h4 { font-size: 1.52778vw; }
  .cpcs-esis h5 { font-size: 1.25vw; }
  .cpcs-esis h6 { font-size: 1vw; }
  .cpcs-esis p { font-size: 1.11111vw; }
  .cpcs-esis p.text-sm { font-size: 0.83333vw; }
  /* Common styles */
  .py-xl-dkp { padding-top: 5.55556vw !important; padding-bottom: 5.55556vw !important; }
  .py-lg-dkp { padding-top: 4.16667vw !important; padding-bottom: 4.16667vw !important; }
  .py-md-dkp { padding-top: 2.77778vw !important; padding-bottom: 2.77778vw !important; }
  .py-sm-dkp { padding-top: 1.38889vw !important; padding-bottom: 1.38889vw !important; }
  .py-xs-dkp { padding-top: 0.69444vw !important; padding-bottom: 0.69444vw !important; }
  .py-no-dkp { padding-top: 0 !important; padding-bottom: 0 !important; }
  .px-xl-dkp { padding-left: 5.55556vw !important; padding-right: 5.55556vw !important; }
  .px-lg-dkp { padding-left: 4.16667vw !important; padding-right: 4.16667vw !important; }
  .px-md-dkp { padding-left: 2.77778vw !important; padding-right: 2.77778vw !important; }
  .px-sm-dkp { padding-left: 1.38889vw !important; padding-right: 1.38889vw !important; }
  .px-xs-dkp { padding-left: 0.69444vw !important; padding-right: 0.69444vw !important; }
  .px-no-dkp { padding-left: 0 !important; padding-right: 0 !important; }
  .pt-xl-dkp { padding-top: 5.55556vw !important; }
  .pt-lg-dkp { padding-top: 4.16667vw !important; }
  .pt-md-dkp { padding-top: 2.77778vw !important; }
  .pt-sm-dkp { padding-top: 1.38889vw !important; }
  .pt-xs-dkp { padding-top: 0.69444vw !important; }
  .pt-no-dkp { padding-top: 0 !important; }
  .pb-xl-dkp { padding-bottom: 5.55556vw !important; }
  .pb-lg-dkp { padding-bottom: 4.16667vw !important; }
  .pb-md-dkp { padding-bottom: 2.77778vw !important; }
  .pb-sm-dkp { padding-bottom: 1.38889vw !important; }
  .pb-xs-dkp { padding-bottom: 0.69444vw !important; }
  .pb-no-dkp { padding-bottom: 0 !important; }
  .pl-xl-dkp { padding-left: 5.55556vw !important; }
  .pl-lg-dkp { padding-left: 4.16667vw !important; }
  .pl-md-dkp { padding-left: 2.77778vw !important; }
  .pl-sm-dkp { padding-left: 1.38889vw !important; }
  .pl-xs-dkp { padding-left: 0.69444vw !important; }
  .pl-no-dkp { padding-left: 0 !important; }
  .pr-xl-dkp { padding-right: 5.55556vw !important; }
  .pr-lg-dkp { padding-right: 4.16667vw !important; }
  .pr-md-dkp { padding-right: 2.77778vw !important; }
  .pr-sm-dkp { padding-right: 1.38889vw !important; }
  .pr-xs-dkp { padding-right: 0.69444vw !important; }
  .pr-no-dkp { padding-right: 0 !important; } 
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis h1 { font-size: 3.888889vw; }
  .cpcs-esis h2 { font-size: 3.33333vw; }
  .cpcs-esis h3 { font-size: 2.5vw; }
  .cpcs-esis h4 { font-size: 1.8vw; }
  .cpcs-esis h5 { font-size: 1.25vw; }
  .cpcs-esis h6 { font-size: 1.11111vw; }
  .cpcs-esis p { font-size: 1.25vw; }
  .cpcs-esis p.text-sm { font-size: 0.97222vw; }
  /* Common styles */
  .py-xl-dkp { padding-top: 5.55556vw !important; padding-bottom: 5.55556vw !important; }
  .py-lg-dkp { padding-top: 4.16667vw !important; padding-bottom: 4.16667vw !important; }
  .py-md-dkp { padding-top: 2.77778vw !important; padding-bottom: 2.77778vw !important; }
  .py-sm-dkp { padding-top: 1.38889vw !important; padding-bottom: 1.38889vw !important; }
  .py-xs-dkp { padding-top: 0.69444vw !important; padding-bottom: 0.69444vw !important; }
  .py-no-dkp { padding-top: 0 !important; padding-bottom: 0 !important; }
  .px-xl-dkp { padding-left: 5.55556vw !important; padding-right: 5.55556vw !important; }
  .px-lg-dkp { padding-left: 4.16667vw !important; padding-right: 4.16667vw !important; }
  .px-md-dkp { padding-left: 2.77778vw !important; padding-right: 2.77778vw !important; }
  .px-sm-dkp { padding-left: 1.38889vw !important; padding-right: 1.38889vw !important; }
  .px-xs-dkp { padding-left: 0.69444vw !important; padding-right: 0.69444vw !important; }
  .px-no-dkp { padding-left: 0 !important; padding-right: 0 !important; }
  .pt-xl-dkp { padding-top: 5.55556vw !important; }
  .pt-lg-dkp { padding-top: 4.16667vw !important; }
  .pt-md-dkp { padding-top: 2.77778vw !important; }
  .pt-sm-dkp { padding-top: 1.38889vw !important; }
  .pt-xs-dkp { padding-top: 0.69444vw !important; }
  .pt-no-dkp { padding-top: 0 !important; }
  .pb-xl-dkp { padding-bottom: 5.55556vw !important; }
  .pb-lg-dkp { padding-bottom: 4.16667vw !important; }
  .pb-md-dkp { padding-bottom: 2.77778vw !important; }
  .pb-sm-dkp { padding-bottom: 1.38889vw !important; }
  .pb-xs-dkp { padding-bottom: 0.69444vw !important; }
  .pb-no-dkp { padding-bottom: 0 !important; }
  .pl-xl-dkp { padding-left: 5.55556vw !important; }
  .pl-lg-dkp { padding-left: 4.16667vw !important; }
  .pl-md-dkp { padding-left: 2.77778vw !important; }
  .pl-sm-dkp { padding-left: 1.38889vw !important; }
  .pl-xs-dkp { padding-left: 0.69444vw !important; }
  .pl-no-dkp { padding-left: 0 !important; }
  .pr-xl-dkp { padding-right: 5.55556vw !important; }
  .pr-lg-dkp { padding-right: 4.16667vw !important; }
  .pr-md-dkp { padding-right: 2.77778vw !important; }
  .pr-sm-dkp { padding-right: 1.38889vw !important; }
  .pr-xs-dkp { padding-right: 0.69444vw !important; }
  .pr-no-dkp { padding-right: 0 !important; }}

@media (max-width: 767px) {
  .cpcs-esis h1 { font-size: 9.44444vw; }
  .cpcs-esis h2 { font-size: 8.33333333vw; }
  .cpcs-esis h3 { font-size: 6vw; }
  .cpcs-esis h4 { font-size: 4vw; }
  .cpcs-esis h5 { font-size: 3.3333vw; }
  .cpcs-esis h6 { font-size: 3vw; }
  .cpcs-esis p { font-size: 3.88888889vw; }
  .cpcs-esis p.text-sm { font-size: 3.05556vw; }
  /* Common styles - Custom Additional */
  .cpcs-esis .desktop-only { display: none; }
  .cpcs-esis .mobile-only { display: block; }
  .py-xl-mbl { padding-top: 80px !important; padding-bottom: 80px !important; }
  .py-lg-mbl { padding-top: 60px !important; padding-bottom: 60px !important; }
  .py-md-mbl { padding-top: 40px !important; padding-bottom: 40px !important; }
  .py-sm-mbl { padding-top: 20px !important; padding-bottom: 20px !important; }
  .py-xs-mbl { padding-top: 10px !important; padding-bottom: 10px !important; }
  .py-no-mbl { padding-top: 0 !important; padding-bottom: 0 !important; }
  .px-xl-mbl { padding-left: 80px !important; padding-right: 80px !important; }
  .px-lg-mbl { padding-left: 60px !important; padding-right: 60px !important; }
  .px-md-mbl { padding-left: 40px !important; padding-right: 40px !important; }
  .px-sm-mbl { padding-left: 20px !important; padding-right: 20px !important; }
  .px-xs-mbl { padding-left: 10px !important; padding-right: 10px !important; }
  .px-no-mbl { padding-left: 0 !important; padding-right: 0 !important; }
  .pt-xl-mbl { padding-top: 80px !important; }
  .pt-lg-mbl { padding-top: 60px !important; }
  .pt-md-mbl { padding-top: 40px !important; }
  .pt-sm-mbl { padding-top: 20px !important; }
  .pt-xs-mbl { padding-top: 10px !important; }
  .pt-no-mbl { padding-top: 0 !important; }
  .pb-xl-mbl { padding-bottom: 80px !important; }
  .pb-lg-mbl { padding-bottom: 60px !important; }
  .pb-md-mbl { padding-bottom: 40px !important; }
  .pb-sm-mbl { padding-bottom: 20px !important; }
  .pb-xs-mbl { padding-bottom: 10px !important; }
  .pb-no-mbl { padding-bottom: 0 !important; }
  .pl-xl-mbl { padding-left: 80px !important; }
  .pl-lg-mbl { padding-left: 60px !important; }
  .pl-md-mbl { padding-left: 40px !important; }
  .pl-sm-mbl { padding-left: 20px !important; }
  .pl-xs-mbl { padding-left: 10px !important; }
  .pl-no-mbl { padding-left: 0 !important; }
  .pr-xl-mbl { padding-right: 80px !important; }
  .pr-lg-mbl { padding-right: 60px !important; }
  .pr-md-mbl { padding-right: 40px !important; }
  .pr-sm-mbl { padding-right: 20px !important; }
  .pr-xs-mbl { padding-right: 10px !important; }
  .pr-no-mbl { padding-right: 0 !important; }}

/* cpcs-esis-dropdown Styles */
.cpcs-esis-dropdown {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  min-width: 148px
}

.cpcs-esis-dropdown__cta {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 43px 0 23px;
  border-radius: 20px;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #f4f4f4;
  background: #f4f4f4
}

.bg-black .cpcs-esis-dropdown__cta {
  border-color: #313131;
  background: #313131;
  color: #fff
}

.selected .cpcs-esis-dropdown__cta {
  background: transparent !important
}

.disabled .cpcs-esis-dropdown__cta {
  opacity: .3;
  cursor: default;
  pointer-events: none
}

.cpcs-esis-dropdown__cta:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  background: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-selectbox-black.svg') no-repeat 50% 50%;
  background-size: 100% 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: .81
}

.bg-black .cpcs-esis-dropdown__cta:after {
  background-image: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-selectbox-white.svg')
}

.cpcs-esis-dropdown__cta:hover,
.cpcs-esis-dropdown__cta:focus,
.cpcs-esis-dropdown__cta:active {
  border-color: #ddd;
  background: #ddd
}

.cpcs-esis-dropdown__cta:hover:after,
.cpcs-esis-dropdown__cta:focus:after,
.cpcs-esis-dropdown__cta:active:after {
  opacity: 1
}

.bg-black .cpcs-esis-dropdown__cta:hover,
.bg-black .cpcs-esis-dropdown__cta:focus,
.bg-black .cpcs-esis-dropdown__cta:active {
  border-color: #555;
  background: #555
}

.disabled .cpcs-esis-dropdown__cta:hover,
.disabled .cpcs-esis-dropdown__cta:focus,
.disabled .cpcs-esis-dropdown__cta:active {
  border-color: #f4f4f4;
  background: #f4f4f4
}

.bg-black .disabled .cpcs-esis-dropdown__cta:hover,
.bg-black .disabled .cpcs-esis-dropdown__cta:focus,
.bg-black .disabled .cpcs-esis-dropdown__cta:active {
  border-color: #313131;
  background: #313131
}

.cpcs-esis-dropdown__cta.data-in:focus {
  border-color: #0381fe;
  background: #fff
}

.bg-black .cpcs-esis-dropdown__cta.data-in:focus {
  background: #000;
  color: #fff
}

.cpcs-esis-dropdown__list-wrap {
  display: none;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  background: #f4f4f4;
  overflow: hidden
}

.cpcs-esis-dropdown__list-wrap.scrollbar {
  position: absolute;
  max-height: none;
  max-width: none
}

.cpcs-esis-dropdown__list-wrap.scrollbar .scrollbar-vertical__track {
  top: 16px;
  bottom: 16px
}

.bg-black .cpcs-esis-dropdown__list-wrap {
  background: #363636
}

.selected .cpcs-esis-dropdown__list-wrap {
  display: block;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2)
}

.cpcs-esis-dropdown__option-list {
  -ms-overflow-style: none;
  scrollbar-width: none
}

.cpcs-esis-dropdown__option-list::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent
}

.cpcs-esis-dropdown__option a {
  display: block;
  position: relative;
  padding: 16px;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word
}

.bg-black .cpcs-esis-dropdown__option a {
  color: #fff
}

.cpcs-esis-dropdown__option a:hover,
.cpcs-esis-dropdown__option a:focus,
.cpcs-esis-dropdown__option a:active {
  background: #ddd
}

.bg-black .cpcs-esis-dropdown__option a:hover,
.bg-black .cpcs-esis-dropdown__option a:focus,
.bg-black .cpcs-esis-dropdown__option a:active {
  background: #555
}

.cpcs-esis-dropdown__option a.active {
  padding-right: 44px;
  font-weight: bold
}

.cpcs-esis-dropdown__option a.active:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  background: url('https://www.samsung.com/etc.clientlibs/samsung/clientlibs/consumer/global/clientlib-common/resources/images/icon-selectbox-check.svg') no-repeat;
  background-size: 100% auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.cpcs-esis-dropdown__option a.disabled {
  opacity: .3;
  cursor: default;
  pointer-events: none
}
.cpcs-esis .cta.cta--underline.cta--transparent p, .cpcs-esis .cta.cta--underline.cta--transparent p:after{
  color: #000 !important;
}

@media only screen and (min-width:768px) and (max-width:1440px) {
  .cpcs-esis-dropdown { min-width: 10.27777778vw; max-width: 45vw }
  .cpcs-esis-dropdown__cta { height: 2.77777778vw; padding: 0vw 2.98611111vw 0vw 1.59722222vw; border-radius: 1.38888889vw; font-size: .97222222vw; line-height: 2.63888889vw }
  .cpcs-esis-dropdown__cta:after { right: 1.11111111vw; width: 1.66666667vw; height: 1.66666667vw }
  .cpcs-esis-dropdown__list-wrap { border-radius: 1.38888889vw }
  .cpcs-esis-dropdown__list-wrap.scrollbar .scrollbar-vertical__track { top: 1.11111111vw; bottom: 1.11111111vw }
  .cpcs-esis-dropdown__option a { padding: 1.04166667vw 1.11111111vw; font-size: .97222222vw; line-height: 1.25vw }
  .cpcs-esis-dropdown__option a.active { padding-right: 3.05555556vw }
  .cpcs-esis-dropdown__option a.active:after { right: 1.11111111vw; width: 1.66666667vw; height: 1.66666667vw }}

@media only screen and (max-width:767px) {
  .cpcs-esis-dropdown { min-width: 41.11111111vw; max-width: 86.66666667vw }
  .cpcs-esis-dropdown__cta { height: 11.11111111vw; padding: 0vw 11.94444444vw 0vw 4.16666667vw; border-radius: 5.55555556vw; font-size: 3.33333333vw; line-height: 10.55555556vw }
  .cpcs-esis-dropdown__cta:after { right: 4.44444444vw; width: 6.66666667vw; height: 6.66666667vw }
  .cpcs-esis-dropdown__list-wrap { border-radius: 5.55555556vw }
  .cpcs-esis-dropdown__list-wrap.scrollbar .scrollbar-vertical__track { top: 4.44444444vw; bottom: 4.44444444vw }
  .cpcs-esis-dropdown__option a { padding: 4.44444444vw; font-size: 3.33333333vw; line-height: 4.44444444vw }
  .cpcs-esis-dropdown__option a.active { padding-right: 12.22222222vw }
  .cpcs-esis-dropdown__option a.active:after { right: 4.44444444vw; width: 6.66666667vw; height: 6.66666667vw }
  .cta--arrow.cta--underline:after { height: 0.13889vw; }}

@media only screen and (max-width:360px) {
  .cpcs-esis-dropdown { min-width: 148px; max-width: 312px }
  .cpcs-esis-dropdown__cta { height: 40px; padding: 0 43px 0 15px; border-radius: 20px; font-size: 12px; line-height: 38px }
  .cpcs-esis-dropdown__cta:after { right: 16px; width: 24px; height: 24px }
  .cpcs-esis-dropdown__list-wrap { border-radius: 20px }
  .cpcs-esis-dropdown__list-wrap.scrollbar .scrollbar-vertical__track { top: 16px; bottom: 16px }
  .cpcs-esis-dropdown__option a { padding: 16px; font-size: 12px; line-height: 16px }
  .cpcs-esis-dropdown__option a.active { padding-right: 44px }
  .cpcs-esis-dropdown__option a.active:after { right: 16px; width: 24px; height: 24px }
}