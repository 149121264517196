.cpcs-esis_show-case-card-item-small { width: 100%; height: 100%; padding: 3px; margin: auto;}
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card {width: 100%; height: 100%; background-color: #fff; padding: 10px 10px 20px;} 
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-inner { height: 100%; width: 100%; display: flex; align-items: center; justify-content: flex-start; flex-direction: column; gap: 10px;}
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-img-container { width: 280px; height:180px; }
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-img-container .img-wrapper{  width: 100%; height: 100%;  display: flex; align-items: center; justify-content: center; }
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-title-container {  max-width:  300px; width:100%; height: 45px; text-align: center; display: flex; align-items: center; justify-content: center;
}
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-btn { height:38px; }
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-btn-inner { height: 100%; margin: auto 0;}
/* large */
.cpcs-esis_show-case-card-item-large { width: 100%; height: 100%; padding: 3px; margin: auto; }
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card { width: 100%; height: 100%; margin: 0 auto; padding: 10px 10px 20px; background-color: #fff; }
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-inner { width: 100%; height: 100%;  display: flex; align-items: center; justify-content: center; flex-direction: column;}
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-img-container { width: 500px; height:380px; }
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-img-container .img-wrapper{  width: 100%; height: 100%;  display: flex; align-items: center; justify-content: center; }
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-title-container { max-width: 500px; width: 100%; height: 100px;  text-align: center; display: flex; align-items: center; justify-content: center;}
/* banner */

.cpcs-esis-product-card-inner { width: 25%;  height: 50%; flex :1 1 25%;} 
.cpcs-esis-banner-card-wrapper { width: 50%; height: 50%; flex :1 1 50%; display: inline-block;}
.cpcs-esis-banner-card-inner { width: 100%; height: 100%; padding:3px;  display: inline-block;}


@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis_show-case-card-item-small {padding: 0.20833vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card { padding: 0.69444vw 0.69444vw 1.38889vw;} 
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-inner { gap: 0.69444vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-img-container {width: 19.44444vw; height:12.50000vw; }
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-title-container { max-width: 20.83333vw;  height:3.12500vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-btn { height:2.63889vw; }
  /* large */
  .cpcs-esis_show-case-card-item-large {padding: 0.20833vw; }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card { padding: 0.69444vw 0.69444vw 1.38889vw;  }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-img-container { width: 34.72222vw; height:26.38889vw; }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-title-container { max-width: 34.72222vw; height: 6.94444vw;}
  /* banner */

  .cpcs-esis-banner-card-inner { padding:0.20833vw;  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis_show-case-card-item-small {padding: 0.20833vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card { padding: 0.69444vw 0.69444vw 1.38889vw;} 
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-inner { gap: 0.69444vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-img-container {width: 19.44444vw; height:12.50000vw; }
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-title-container { max-width: 20.83333vw;  height:3.12500vw;}
  .cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-btn { height:2.63889vw; }
  /* large */
  .cpcs-esis_show-case-card-item-large {padding: 0.20833vw; }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card { padding: 0.69444vw 0.69444vw 1.38889vw;  }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-img-container { width: 34.72222vw; height:26.38889vw; }
  .cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-title-container { max-width: 34.72222vw; height: 6.94444vw;}
  /* banner */
}

@media (max-width: 767px) {
.cpcs-esis_show-case-card-item-small { padding:0.55556vw; }
/* max */
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card { width: 100%; max-width: 100%; max-height:63.88889vw; height: 100%; padding: 2.77778vw 2.77778vw 5.55556vw;}
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-inner { gap: 1.38889vw;}
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-img-container { width: 42.77778vw; height:33.33333vw; }
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-title-container { width: 100%; max-width: calc(100% - 5.55556vw); height: 11.11111vw;  }
.cpcs-esis_show-case-card-item-small .cpcs-esis-sm-card-btn { height:8.33333vw; }

/* large */
.cpcs-esis_show-case-card-item-large { padding:0.556vw;  }
/* max */
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card { max-width: calc(100vw - 1.11111vw);  max-height:calc(100% - 1.11111vw);  padding:2.77778vw 2.77778vw 5.55556vw;  justify-content: space-evenly;}
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-img-container { width: calc(100vw - 11.11111vw); height:70.83333vw; }
.cpcs-esis_show-case-card-item-large .cpcs-esis-sm-card-title-container { max-width: 93.33333vw; height:13.88889vw; }

/* banner */
.cpcs-esis-product-card-inner { width: 50%;  height: 63.88888889vw;  flex :1 1 50%;} 
.cpcs-esis-banner-card-wrapper { width: 100vw; height: auto; flex :1;}
.cpcs-esis-banner-card-inner {  padding:0.55556vw; }
}