.cpcs-esis-wrapper { width: 100%; margin: 0; padding: 0;  }
.cpcs-esis-wrapper * { box-sizing: border-box; }
.cpcs-esis-container { width: 100%; max-width: 1260px; margin: 0 auto; padding: 0; }
.cpcs-esis-container-wrapper {min-height: 120vh;}
.cpcs-esis-section-wrapper { width: 100%; margin: 0;padding: 0; }
.cpcs-esis-section { width: 100%; /* max-width: 1220px;*/ margin: 0 auto; padding: 0; } 
.cpcs-esis-section-inner { text-align: center; padding: 70px 0; }
.cpcs-esis-section-title-inner { text-align: center; padding: 40px 0; }
.cpcs-esis .desktop-only { display: block;}
.cpcs-esis .mobile-only { display: none;}

.cpcs_esis_home-title_logo { width:200px}
/* Typography */
 .cpcs-esis p.text-heavy { font-family: 'SamsungSharpSans'; font-weight: normal; }
 [dir="rtl"] .cpcs-esis p.text-heavy { font-family: 'SamsungSharpSans','SamsungOneArabic'; font-weight: 700; }
 .cpcs-esis p.text-lg { font-size: 18px; line-height: 1.4; margin: 0 0 10px 0; }
 .cpcs-esis p.text-small { font-size: 12px; line-height: 1.4; }
 .cpcs-esis .text-white { color: #ffffff !important;  } 
 .cpcs-esis .text-bold { font-weight: 700; }
 .cpcs-esis .cta-sm { font-size: 12px; }
 .cpcs-esis .cta-heavy { font-family: 'SamsungOne'; font-weight: 700; }
 [dir="rtl"] .cpcs-esis .cta-heavy { font-family: 'SamsungOneArabic'; font-weight: 700; }
 .cpcs-esis .cta-lite { font-family: 'SamsungOne'; font-weight: 400; }
 [dir="rtl"] .cpcs-esis .cta-lite { font-family:'SamsungOneArabic'; font-weight: 400; }
 .cpcs-esis sup { font-size: 35%; }
 .cpcs-esis .bg-white { background-color: #ffffff;}
 .cpcs-esis .bg-off-white { background-color: #f5f5f5;}

 @media (min-width:1025px) and (max-width: 1440px) {
  .cpcs-esis-section-inner { padding: 4.333333vw 0; }
  .cpcs-esis-section-title-inner { padding: 2.77778vw 0; }
  .cpcs_esis_home-title_logo { width:13.88889vw }
  /* Typography */
  .cpcs-esis p.text-lg { font-size: 1.25000vw;  margin: 0 0 0.69444vw 0; }
  .cpcs-esis p.text-small { font-size: 0.83333vw; }
  .cpcs-esis .cta-sm { font-size:0.83333vw; margin-bottom: 0; }
}
@media (min-width:768px) and (max-width: 1024px) {
  .cpcs-esis-section-inner { padding: 4.333333vw 0; }
  .cpcs-esis-section-title-inner { padding: 2.77778vw 0; }
  .cpcs_esis_home-title_logo { width:13.88889vw }
  /* Typography */
  .cpcs-esis p.text-lg { font-size:1.38889vw;  margin: 0 0 0.69444vw 0; }
  .cpcs-esis p.text-small { font-size:0.97222vw; }
  .cpcs-esis .cta-sm { font-size:0.97222vw; margin-bottom: 0; }
}
@media (max-width:767px) {
  .cpcs-esis-section-inner {  padding: 9.44444vw 0; }
  .cpcs-esis-section-title-inner { padding:6.88889vw 0; }
  .cpcs_esis_home-title_logo { width:34.72222222vw }
  /* Common styles - Custom Additional */
  .cpcs-esis .desktop-only { display: none; }
  .cpcs-esis .mobile-only { display: block; }
  /* Typography */
  .cpcs-esis p.text-lg { font-size: 5vw; margin-bottom: 2.6666667vw; }
  .cpcs-esis .cta { font-size: 3.666667vw; margin-bottom: 0; }
  .cpcs-esis .cta-sm { font-size: 3.33333333vw; margin-bottom: 0; }
  .cpcs-esis-container-wrapper { min-height: 100vh; }
}

/* Sharaf DG - Mobile Padding */ 

@media (max-width:767px) {
  main#main > .container { padding-left: 0 !important; padding-right: 0 !important; }
}