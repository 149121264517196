.cpcs-esis-blank-space { width: 100%; margin: 0 auto; padding: 0;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-large { width: 100%; height: 96px;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-medium { width: 100%; height: 64px;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-small { width: 100%; height: 32px;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-bg-darkgray { background-color: #313131;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-bg-lightgray { background-color: #f7f7f7;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-bg-black { background-color: #000000;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-bg-white { background-color: #ffffff;}
.cpcs-esis-blank-space .cpcs-esis-blank-space-bg-transparent{ background-color:transparent;}


@media (min-width: 1025px) and (max-width: 1440px) {
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-large { height: 6.66666667vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-medium { height: 4.44444444vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-small { height: 2.22222222vw;}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-large { height: 6.66666667vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-medium { height: 4.44444444vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-dpk-small { height: 2.22222222vw;}
}

@media (max-width: 767px) {
  .cpcs-esis-blank-space .cpcs-esis-blank-space-mbl-large { height: 17.77777778vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-mbl-medium { height: 8.88888889vw;}
  .cpcs-esis-blank-space .cpcs-esis-blank-space-mbl-small { height: 4.44444444vw;}
}
