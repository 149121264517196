
@media (min-width: 768px) {
.cpcs-esis_overlay-top-left {	width: 50%; top: 5%; left: 3%; text-align: left;}
.cpcs-esis_overlay-top-center { width: 50%; top: 5%; left: 22%; text-align: center; }
.cpcs-esis_overlay-top-right { width: 50%; top: 5%; left: 49%; text-align: left; }
.cpcs-esis_overlay-middle-left {	width: 50%; top: 15%; left: 3%; text-align: left;}
.cpcs-esis_overlay-middle-center { width: 50%; top: 15%; left: 22%; text-align: center; }
.cpcs-esis_overlay-middle-right { width: 50%; top: 15%; left: 49%;   text-align: left; }
.cpcs-esis_overlay-bottom-left {	width: 50%; bottom: 5%; left: 3%; text-align: left;}
.cpcs-esis_overlay-bottom-center { width: 50%; bottom: 5%; left: 22%; text-align: center; }
.cpcs-esis_overlay-bottom-right { width: 50%; bottom: 5%; left: 48%; text-align: left; }

[dir="rtl"] .cpcs-esis_overlay-top-left {   left: 0%; text-align: right;}
[dir="rtl"] .cpcs-esis_overlay-top-center {  text-align: center; }
[dir="rtl"] .cpcs-esis_overlay-top-right {  text-align: right; }
[dir="rtl"] .cpcs-esis_overlay-middle-left {	 left: 0%; text-align: right;} 
[dir="rtl"] .cpcs-esis_overlay-middle-center {  text-align: center; }
[dir="rtl"] .cpcs-esis_overlay-middle-right {    text-align: right; }
[dir="rtl"] .cpcs-esis_overlay-bottom-left {	  left: 0%;  text-align: right;}
[dir="rtl"] .cpcs-esis_overlay-bottom-center { text-align: center; }
[dir="rtl"] .cpcs-esis_overlay-bottom-right { text-align: right; }
} 

@media (max-width: 767px) {
  .cpcs-esis_overlay-top-center-mo { width: 100% ; text-align: center ; top: 0%; left: auto; right: auto; bottom: auto ; }
  .cpcs-esis_overlay-middle-center-mo { width: 100% ; text-align: center ; top: 25%; left: auto; right: auto; bottom: auto ; }
  .cpcs-esis_overlay-bottom-center-mo { width: 100% ; text-align: center ; bottom: 10% ; left: auto;  right: auto; top: auto ; }
  [dir="rtl"] .cpcs-esis_overlay-top-center-mo { width: 100% ; text-align: center ; top: 0%; left: auto; right: auto; bottom: auto ; }
  [dir="rtl"] .cpcs-esis_overlay-middle-center-mo { width: 100% ; text-align: center ; top: 25%; left: auto; right: auto; bottom: auto ; }
  [dir="rtl"] .cpcs-esis_overlay-bottom-center-mo { width: 100% ; text-align: center ; bottom: 10% ; left: auto;  right: auto; top: auto ; }
}


