.cpcs-esis .swiper { width: 100%;  --swiper-navigation-color: #000; --swiper-pagination-color: #000; --swiper-navigation-size: 20px; display: flex; justify-content: center; align-items:stretch; }
.cpcs-esis .swiper{ --swiper-theme-color: #000000 !important; --swiper-navigation-color: #ffffff; --swiper-pagination-color: #000000; }
.cpcs-esis .swiper-wrapper { align-items: stretch; }
.cpcs-esis .swiper-button-prev,.cpcs-esis .swiper-button-next { position: absolute; top: 50%; margin-top: calc(-1 * var(--swiper-navigation-size) / 2); z-index: 10; cursor: pointer;
  display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; border-radius: 50%; text-align: center; color: var(--swiper-navigation-color,var(--swiper-theme-color)); background-color: #000000; border-color: rgba(255,255,255,0.15); width: 40px !important; height: 40px !important; }
  .cpcs-esis .swiper-slide { height: auto!important; }
  .cpcs-esis .swiper-button-prev, .cpcs-esis .swiper-rtl .swiper-button-next { left: var(--swiper-navigation-sides-offset, 4px) !important; right: auto !important; }
  .cpcs-esis .swiper-button-next,.cpcs-esis .swiper-rtl .swiper-button-prev { right: var(--swiper-navigation-sides-offset, 4px) !important; left: auto !important; }
  .cpcs-esis .swiper-button-disabled.swiper-button-lock.swiper-button-prev, .cpcs-esis .swiper-button-next.swiper-button-lock.swiper-button-disabled { display: none !important;}
  .cpcs-esis .swiper-button-prev::after,.cpcs-esis .swiper-rtl .swiper-button-next::after { position: absolute; left:auto;}
  .cpcs-esis .swiper-button-next::after,.cpcs-esis .swiper-rtl .swiper-button-prev::after { position: absolute; left:auto;}
  .cpcs-esis .swiper-slide .cpcs-esis_featured-collection-item { height: 100%; max-width: 100%; min-height: 100%; align-self: stretch; width: 100%;}
  .cpcs-esis .swiper-pagination {  bottom: 0; }
  .cpcs-esis .swiper-pagination-bullet { width: 10% !important; height: 2px !important; display: inline-block; border-radius: 0px !important; margin: 8px !important; opacity: 1 !important; background: rgba(0,0,0,0.20) !important; border: 1px solid rgba(255,255,255,0.20) !important; }
  .cpcs-esis .swiper-pagination-bullet-active { opacity: 1 !important; color: black  !important; background: rgba(0,0,0) !important; border: 1px solid rgba(255,255,255,0.4) !important; }
  .cpcs-esis .swiper-pagination-fraction,.cpcs-esis .swiper-pagination-custom,.cpcs-esis .swiper-horizontal > .swiper-pagination-bullets,.cpcs-esis .swiper-pagination-bullets.swiper-pagination-horizontal {bottom: 46px !important;}

  .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-fraction, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-custom,.cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-horizontal > .swiper-pagination-bullets, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-bullets.swiper-pagination-horizontal { bottom: 5px !important;}
/* Hero KV carousels */
.cpcs-esis_mainkv_swiper.swiper { height: auto !important; display: flex; justify-content: center; align-items:stretch; }
.cpcs-esis_mainkv_swiper.swiper .swiper-wrapper { height: auto!important;}
.cpcs-esis_mainkv_swiper.swiper .swiper-slide { height: auto!important;}

/* Product Listing */
.cpcs-esis_product_listing_swiper.swiper .swiper-wrapper { height: 100%!important;}

/* Storytelling Square */
.cpcs-esis_story-square_swiper.swiper .swiper-wrapper { height: 1870px; width:1140px!important;}

/* Choose tile pagination default */
.cpcs-esis-default-swiper .swiper .swiper-wrapper { padding-bottom: 60px;}
.cpcs-esis-default-swiper .swiper .swiper-pagination-bullet { width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px)) !important; height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px))!important; border-radius: var(--swiper-pagination-bullet-border-radius, 50%) !important; background: #757575 !important; margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px) !important; }
.cpcs-esis-default-swiper .swiper .swiper-pagination-bullet-active{ background:#000!important; border: 0px; }
.cpcs-esis-default-swiper .swiper .swiper-pagination-bullets { width: auto !important; padding: 0.11vw 0.35vw  !important; left: 50% !important; bottom: 0 !important; -webkit-transform: translateX(-50%) !important; transform: translateX(-50%)!important; border-radius: 4.44444444vw !important; background: rgba(255,255,255,0.9)!important; border: .5px solid #d5d3d3; }


@media (min-width:768px) and (max-width: 1440px) { 
  .cpcs-esis .swiper {  --swiper-navigation-size: 1.38889vw}
  .cpcs-esis .swiper-button-prev,.cpcs-esis .swiper-button-next { width: 2.77778vw !important; height: 2.77778vw !important;}
  .cpcs-esis .swiper-pagination-bullet {  height: 0.13889vw!important;  margin:0.55556vw !important }  
  .cpcs-esis .swiper-pagination-fraction, .cpcs-esis .swiper-pagination-custom, .cpcs-esis .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {bottom: 3.19444vw!important;}
  .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-fraction, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-custom,.cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-horizontal > .swiper-pagination-bullets, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-bullets.swiper-pagination-horizontal {bottom: 0.19444vw!important;}
/* Choose tile pagination default */
.cpcs-esis-default-swiper .swiper .swiper-wrapper { padding-bottom: 4.16667vw;}
/* Storytelling Square */
.cpcs-esis_story-square_swiper.swiper .swiper-wrapper { height:129.86111vw; width:79.16667vw!important;}
}

@media(max-width: 767px) {
  .cpcs-esis .swiper { --swiper-navigation-size: 4.44444vw}
  .cpcs-esis .swiper-button-prev,.cpcs-esis .swiper-button-next { width:8.88889vw!important; height:8.88889vw !important; }
  .cpcs-esis .swiper-pagination-bullet { width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px)) !important; height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px))!important; border-radius: var(--swiper-pagination-bullet-border-radius, 50%) !important; background: rgba(255,255,255,0.35) !important; margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px) !important; }
  .cpcs-esis .swiper-pagination-bullet-active{ background:rgba(255,255,255,0.8)!important; border: 0px; }
  .cpcs-esis .swiper-pagination-bullets { width: auto !important; padding: 0.35vw 2.666667vw !important; left: 50% !important; bottom: 15px !important; -webkit-transform: translateX(-50%) !important; transform: translateX(-50%)!important; border-radius: 4.44444444vw !important; background: rgba(0,0,0,0.45)!important; }
  .cpcs-esis .swiper-pagination-bullets span { vertical-align: middle !important;}
  .cpcs-esis .swiper-pagination-fraction, .cpcs-esis .swiper-pagination-custom,.cpcs-esis .swiper-horizontal > .swiper-pagination-bullets, .cpcs-esis .swiper-pagination-bullets.swiper-pagination-horizontal {bottom:6.38889vw!important;}
  
  .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-fraction, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-custom,.cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-horizontal > .swiper-pagination-bullets, .cpcs-esis .cpcs-esis_story-square_swiper.swiper .swiper-pagination-bullets.swiper-pagination-horizontal {bottom:1.38889vw!important;}
  .cpcs-esis_product_listing_swiper.swiper{ height: 165.82vw!important; }  
  .cpcs-esis_product_listing_swiper.swiper .swiper-wrapper { height: 100%!important; }
  .cpcs-esis_product_listing_swiper.swiper .swiper-slide { height:calc((100% - 12.25vw) / 2)!important;}

  .cpcs-esis_mainkv_swiper .swiper-button-prev, .swiper-rtl.cpcs-esis_mainkv_swiper .swiper-button-next { display: none; }
  .cpcs-esis_mainkv_swiper .swiper-button-next, .swiper-rtl.cpcs-esis_mainkv_swiper .swiper-button-prev { display: none; }

  /* Storytelling Square */
.cpcs-esis_story-square_swiper.swiper .swiper-wrapper { height: 130.9444vw; width:100% !important;}
  
  /* Choose tile pagination default */
  .cpcs-esis-default-swiper .swiper .swiper-wrapper { padding-bottom: 60px;}
  .cpcs-esis-default-swiper .swiper .swiper-pagination-bullet { width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px)) !important; height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px))!important; border-radius: var(--swiper-pagination-bullet-border-radius, 50%) !important; background: #757575 !important; margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px) !important; }
  .cpcs-esis-default-swiper .swiper .swiper-pagination-bullet-active{ background:#000!important; border: 0px; }
  .cpcs-esis-default-swiper .swiper .swiper-pagination-bullets { width: auto !important; padding: 0.11vw 0.35vw  !important; left: 50% !important; bottom: 0 !important; -webkit-transform: translateX(-50%) !important; transform: translateX(-50%)!important; border-radius: 4.44444444vw !important; background: rgba(255,255,255,0.9)!important; border: .5px solid #d5d3d3; }

}

